import React from "react";
import PropTypes from "prop-types";
import {
  ClockIconStyled,
  ContentContainer,
  DurationAndPriceContainer,
  DurationContainer,
  ItemContainer,
  PriceContainer,
  ScheduleButton,
  ServiceDurationAndPriceText,
  ServiceIconDefaultStyled,
  ServiceIconStyled,
  ServiceNameText,
  WalletIconStyled,
} from "./ListingServicesComponent.styled";
import { useTranslation } from "react-i18next";
import { useGetImageQuery } from "features/clients/clientsApiSlice";
import { replaceInRoute } from "util/routeHelpers";
import { PAGES } from "constants/pages";

export const Item = (props) => {
  const { t, i18n } = useTranslation();
  const { data: image, isLoading } = useGetImageQuery(props?.item?.imageId);

  const handleMouseDown = (e) => {
    if (e.button === 1) {
      const pathParams = {
        serviceCategoryId: props?.item?.serviceCategory?.id,
        clientId: props?.item?.client?.id,
        clientServiceId: props?.item?.id,
      };
      const resolvedRoute = replaceInRoute(
        PAGES.SCHEDULE_APPOINTMENT.route,
        pathParams
      );
      window.open(resolvedRoute, "_blank");
    }
  };

  return (
    <ItemContainer
      key={props?.itemIndex}
      onMouseDown={handleMouseDown}
    >
      {isLoading || !image ? (
        <ServiceIconDefaultStyled />
      ) : (
        <ServiceIconStyled src={image} />
      )}
      <ContentContainer>
        <ServiceNameText>{props?.item.name}</ServiceNameText>
        <DurationAndPriceContainer>
          <DurationContainer>
            <ClockIconStyled />
            <ServiceDurationAndPriceText>
              {props?.item.durationInMinutes}min
            </ServiceDurationAndPriceText>
          </DurationContainer>
          <PriceContainer>
            <WalletIconStyled />
            <ServiceDurationAndPriceText>
              {props?.item.price}din
            </ServiceDurationAndPriceText>
          </PriceContainer>
        </DurationAndPriceContainer>
        <ScheduleButton
          onClick={(e) => {
            e.stopPropagation();
            props?.onClickSchedule(props?.item);
          }}
          value={t("common.schedule")}
          language={i18n.language}
        ></ScheduleButton>
      </ContentContainer>
    </ItemContainer>
  );
};

Item.propTypes = {
  item: PropTypes.any,
  itemIndex: PropTypes.number,
  onClickSchedule: PropTypes.func,
};

export default Item;
