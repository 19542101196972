import { Calendar } from "react-big-calendar";
import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";

export const CalendarComponentStyled = styled(Calendar)`
  gap: 16px;
  padding: ${(props) => props?.isUser ? "0px" : "0px 16px" };
  .rbc-month-view,
  .rbc-time-view,
  .rbc-agenda-view {
    border-radius: 16px;
    box-shadow: 5px 3px 3px 3px
      ${(props) =>
        hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.3)};
  }
  .rbc-month-header {
    background-color: ${(props) => props?.theme?.colors?.accordionClosed};
    height: 33px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-color: 1px solid transparent;
    .rbc-header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 33px;
    }
  }
  .rbc-event-label {
    display: none;
  }
  .rbc-time-header {
    background-color: ${(props) => props?.theme?.colors?.accordionClosed};
    box-shadow: 16px 0px 0px 0px
      ${(props) => props?.theme?.colors?.accordionClosed};
    height: 33px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-right: chartreuse;
    .rbc-header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 33px;
      font-weight: bold;
      border: none;
    }
  }
  .rbc-day-bg {
    flex: none;
  }
  .rbc-time-view .rbc-row {
    min-height: 0px;
  }
  .rbc-time-content,
  .rbc-day-bg + .rbc-day-bg {
    border-left: none;
  }
  .rbc-time-header > .rbc-time-header-content {
    border-left: none;
  }
  .rbc-btn-group {
    display: flex;
    flex-direction: row;
    gap: 8px;

    > button {
      border-radius: 8px;
      gap: 4px;
      height: 24px;
      display: flex;
      align-items: center;
      border: 1px solid ${(props) => props?.theme?.colors?.timeSlotBg};
    }
  }
  .rbc-calendar {
    border-top-right-radius: 16px;
  }
  .rbc-day-bg:last-of-type {
    border-bottom-right-radius: 16px;
  }
  .rbc-agenda-table > thead {
    background-color: ${(props) => props?.theme?.colors?.accordionClosed};
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }
  .rbc-agenda-table > thead > tr > th {
    text-align: center !important;
  }
  .rbc-agenda-time-cell,
  .rbc-agenda-date-cell,
  .rbc-agenda-event-cell {
    background-color: ${(props) => props?.theme?.colors?.white};
    color: ${(props) => props?.theme?.colors?.black};
    border: 1px solid
      ${(props) => hexToRGB(props?.theme?.colors?.timeSlotBg, 0.3)};
  }
  .rbc-agenda-content {
    overflow-y: auto;
    .rbc-agenda-table {
      overflow: hidden;
      .rbc-header {
        height: 33px;
      }
    }
  }
  .rbc-time-view {
    overflow: auto;
  }
  .rbc-time-view::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: ${(props) => props?.theme?.colors?.timeSlotBg};
  }
  .rbc-time-view::-webkit-scrollbar-track {
    border-radius: 16px;
  }
  .rbc-agenda-empty {
    margin-left: 20px;
  }
  .rbc-time-slot {
    min-width: 82px;
    width: 135;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
  }
  .rbc-agenda-view {
    .rbc-agenda-table {
      table-layout: fixed;
      width: 100%;
    }
    .rbc-agenda-time-cell,
    .rbc-agenda-date-cell {
      width: 214px;
    }
    .rbc-agenda-event-cell {
      width: auto;
    }
  }
  .rbc-week .rbc-day-bg.rbc-today {
    background-color: ${(props) =>
      props?.theme?.colors?.highlightedDayBackground};
    color: ${(props) => props?.theme?.colors?.highlightedDayText};
    border-radius: 50%;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rbc-time-content {
    overflow-y: auto;
  }
  .rbc-time-content::-webkit-scrollbar {
    width: 12px;
    background-color: ${(props) => props?.theme?.colors?.accordionClosed};
  }
  .rbc-time-content::-webkit-scrollbar-thumb {
    background-color: ${(props) => props?.theme?.colors?.timeSlotBg};
    border-radius: 4px;
  }
  .rbc-time-content::-webkit-scrollbar-track {
    border-radius: 4px;
  }
`;
