import React from "react";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import { ReactComponent as AppointmentIcon } from "assets/images/svg/appointments-icon-selected.svg";
import AppointmentPageContent from "components/Appointment/AppointmentPageContent/AppointmentPageContent";

const AppointemntsPage = () => {
  return (
    <MainLayout>
      <PageHeader leftIcon={<AppointmentIcon />} />
      <AppointmentPageContent />
    </MainLayout>
  );
};

export default AppointemntsPage;
