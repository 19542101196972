import React, { useMemo } from "react";
import {
  ClientServiceTableSectionContainer,
  ClientServiceTableSectionStyled,
  ClientServiceTableSectionWithoutHeaderStyled,
} from "./ClientServiceTableSection.styled";
import resourcesTableConstants from "constants/tableConstants/resourcesTableConstants";
import { useTranslation } from "react-i18next";
import attributesTableConstants from "constants/tableConstants/attributesTableConstants";
import ClientServiceTableHeader from "./ClientServiceTableHeader";
import PropTypes from "prop-types";
import {
  deleteAttribute,
  deleteResourceType,
  submit,
} from "features/clientService/clientServiceSlice";
import { useDispatch } from "react-redux";
import {
  setClientServiceAttributeModal,
  setClientServicResourceModal,
  unsetShownModal,
} from "features/modal/modalSlice";
import Table from "components/Table/Table";

const ClientServiceTableSection = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const requiredResourceTypes = useMemo(
    () =>
      props?.requiredResourceTypes?.map?.((requiredResourceType) => {
        const resourceNames = requiredResourceType?.possibleResources?.map(
          (possibleResource) => possibleResource?.resource?.name
        );
        return {
          ...requiredResourceType,
          resourceNames,
        };
      }),
    [props?.requiredResourceTypes]
  );

  const attributes = useMemo(
    () =>
      props?.attributes?.map?.((attribute) => {
        return {
          ...attribute,
        };
      }),
    [props?.attributes]
  );

  const onCancel = () => {
    dispatch(unsetShownModal());
  };

  const editBtnClickAttribute = (attribute) => {
    dispatch(
      setClientServiceAttributeModal({
        selectedItem: attribute,
        onCancel: onCancel,
      })
    );
  };
  const deleteBtnClickAttribute = (id) => {
    dispatch(submit());
    dispatch(deleteAttribute(id));
  };
  const editBtnClickResource = (resource) => {
    dispatch(
      setClientServicResourceModal({
        selectedItem: resource,
        onCancel: onCancel,
      })
    );
  };
  const deleteBtnClickResource = (id) => {
    dispatch(submit());
    dispatch(deleteResourceType(id));
  };

  const handleOpenModalAddResource = () => {
    const nexIndex = requiredResourceTypes.length + 1;
    const newResurse = {
      id: nexIndex,
      selectedItem: null,
    }
    dispatch(
      setClientServicResourceModal({
        selectedItem: newResurse,
        onCancel: onCancel,
      })
    );
  };
  const handleOpenModalAddAttribute = () => {
    dispatch(
      setClientServiceAttributeModal({
        selectedItem: null,
        onCancel: onCancel,
      })
    );
  };

  return (
    <ClientServiceTableSectionContainer>
      <ClientServiceTableSectionStyled>
        <ClientServiceTableHeader
          headerTitle={t("clientService.serviceResources")}
          handleOpenModalAdd={handleOpenModalAddResource}
          addButtonValue={t("clientService.table.resources.addResource")}
        />
        <ClientServiceTableSectionWithoutHeaderStyled>
          <Table
            items={requiredResourceTypes}
            tableHeaders={resourcesTableConstants}
            rowBtnClick={editBtnClickResource}
            deleteBtnClick={deleteBtnClickResource}
            numberOfCharactersPerCell={20}
            startingNumberOfItemsPerPage={5}
            t={t}
            halfSizeHeight
          />
        </ClientServiceTableSectionWithoutHeaderStyled>
      </ClientServiceTableSectionStyled>
      <ClientServiceTableSectionStyled>
        <ClientServiceTableHeader
          headerTitle={t("clientService.serviceAttributes")}
          handleOpenModalAdd={handleOpenModalAddAttribute}
          addButtonValue={t("clientService.table.attributes.addAttribute")}
        />
        <ClientServiceTableSectionWithoutHeaderStyled>
          <Table
            items={attributes}
            tableHeaders={attributesTableConstants}
            rowBtnClick={editBtnClickAttribute}
            deleteBtnClick={deleteBtnClickAttribute}
            numberOfCharactersPerCell={20}
            startingNumberOfItemsPerPage={5}
            t={t}
            halfSizeHeight
          />
        </ClientServiceTableSectionWithoutHeaderStyled>
      </ClientServiceTableSectionStyled>
    </ClientServiceTableSectionContainer>
  );
};

ClientServiceTableSection.propTypes = {
  requiredResourceTypes: PropTypes.array,
  attributes: PropTypes.array,
};

export default ClientServiceTableSection;
