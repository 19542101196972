/*eslint-disable */
import React from "react";
import { ClientInformation } from "./TabContent/Information/ClientInformation";
import PropTypes from "prop-types";
import { ClientLicenseContent } from "./TabContent/Lincense/ClientLicenseContent";
import { ClientResourceContent } from "./TabContent/Resource/ClientResourceContent";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { PAGES } from "constants/pages";
import { ClientServiceTabContent } from "./TabContent/ClientService/ClientServiceTabContent";
import ClientCalendarContent from "./TabContent/Calendar/ClientCalendarContent";

export const ClientContent = (props) => {
  return (
    <Routes>
      <Route
        path={PAGES.CLIENTS_DETAILS_INFO.nestedRoute}
        element={<ClientInformation />}
      />
      <Route
        path={PAGES.CLIENTS_DETAILS_RESOURCES.nestedRoute}
        element={<ClientResourceContent />}
      />
      <Route
        path={PAGES.CLIENTS_DETAILS_SERVICES.nestedRoute}
        element={<ClientServiceTabContent />}
      />
      <Route
        path={PAGES.CLIENTS_DETAILS_CALENDAR.nestedRoute}
        element={<ClientCalendarContent client={props?.client} />}
      />
      <Route
        path={PAGES.CLIENTS_DETAILS_LICENSES.nestedRoute}
        element={<ClientLicenseContent clientId={props?.client?.id} />}
      />

      {
        // BUGS WITH route /client/:clientId
        /* <Route
        path="*"
        element={<Navigate to={PAGES.CLIENTS_DETAILS_INFO.nestedRoute} />}
      /> */
      }
    </Routes>
  );
};
ClientContent.propTypes = {
  client: PropTypes.any,
  activeTab: PropTypes.any,
};
