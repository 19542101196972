import styled from "styled-components";
import { Box } from "@mui/material";
import selectedTheme from "../../themes";
import { ReactComponent as MiniLogoImg } from "../../assets/images/svg/mini-logo-tremium.svg";
import { hexToRGB } from "util/colorHelper";

export const FooterContainer = styled(Box)`
  padding-left: ${(props) =>
    props?.$isSidebarOpened ? "300px" : props?.$authLayout ? "0px" : "95px"};
  transition: padding-left 0.3s ease;
  width: 100%;
  font-weight: 300;
  box-shadow: 0px -1px 1px ${hexToRGB(selectedTheme.colors.shadowColor, 0.03)};
  border-top: 1px solid ${(props) => props?.theme?.colors?.textUncompletedStep};
  background-color: ${(props) => props?.theme?.colors?.footerBackground};
  position: absolute;
  bottom: 0;
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 0.9rem;
  line-height: 1.5;
  left: 0;
  right: 0;
  color: ${(props) => props?.theme?.colors?.darkBlue};
  text-align: center;
  & span {
    color: ${selectedTheme.colors.gray};
    font-weight: 600;
  }
  @media (max-width: 600px) {
    padding: 0 16px 0 16px;
    font-size: 14px;
  }
  @media (max-width: 300px) {
    font-size: 0.5rem;
  }
  & a.tremium-link {
    text-decoration: none;
    color: ${(props) => props?.theme?.colors?.darkBlue};
  }
  & a.tremium-link:visited {
    color: ${selectedTheme.colors.gray};
  }
`;
export const MiniLogo = styled(MiniLogoImg)`
  margin-bottom: 5px;
`;
