import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import FilterButton from "components/Filter/FilterButton/FilterButton";
import {
  FilterPopoverContainer,
  SaveFiltersButton,
} from "./FilterContainer.styled";
import {
  ActionsContainer,
  CancelButton,
  FilterButtonContainer,
  FilterContainerStyled,
  FilterTitle,
  InputFieldsContainer,
  LowerFilterContainer,
  UpperFilterContainer,
  X_IconStyled,
} from "./FilterContainer.styled";
import { useTranslation } from "react-i18next";
import FilterChips from "./FilterChips/FilterChips";
import { useDispatch } from "react-redux";
import { setFilterModal, unsetFilterModal } from "features/modal/modalSlice";

const FilterContainer = (props) => {
  const { t } = useTranslation();
  const [areFiltersShown, setAreFiltersShown] = useState(false);
  const buttonRef = useRef(null);
  const dipatch = useDispatch();

  const saveFilters = () => {
    setAreFiltersShown(false);
    props?.saveFilters?.();
    dipatch(unsetFilterModal());
  };

  return (
    <FilterContainerStyled>
      {props?.isFilterButtonShown && (
        <>
          <div>
            <FilterButtonContainer>
              <FilterButton
                ref={buttonRef}
                onClick={() => {
                  setAreFiltersShown((prevState) => !prevState);
                  dipatch(setFilterModal());
                }}
              />
            </FilterButtonContainer>
            {areFiltersShown && buttonRef.current && (
              <FilterPopoverContainer
                top={
                  buttonRef.current.getBoundingClientRect().bottom +
                  window.scrollY
                }
                left={buttonRef.current.getBoundingClientRect().left}
              >
                <UpperFilterContainer>
                  <FilterTitle>{t("common.filter")}</FilterTitle>
                  <X_IconStyled
                    onClick={() => {
                      setAreFiltersShown(false);
                      dipatch(unsetFilterModal());
                    }}
                  />
                </UpperFilterContainer>
                <LowerFilterContainer>
                  <InputFieldsContainer>
                    {props?.filterComponents?.map?.(
                      (filterComponent) => filterComponent
                    )}
                  </InputFieldsContainer>
                  <ActionsContainer>
                    <CancelButton
                      value={t("common.cancel")}
                      onClick={() => {
                        setAreFiltersShown(false);
                        dipatch(unsetFilterModal());
                      }}
                    />
                    <SaveFiltersButton
                      value={t("common.confirm")}
                      onClick={saveFilters}
                    />
                  </ActionsContainer>
                </LowerFilterContainer>
              </FilterPopoverContainer>
            )}
          </div>
          <FilterChips
            appliedFilters={props?.appliedFilters}
            filters={props?.filters}
            removeFilter={props?.removeFilter}
            saveFilters={props?.saveFilters}
            areFiltersShown={areFiltersShown}
          />
        </>
      )}
    </FilterContainerStyled>
  );
};

FilterContainer.propTypes = {
  filterComponents: PropTypes.array,
  appliedFilters: PropTypes.object,
  filters: PropTypes.array,
  isFilterButtonShown: PropTypes.bool,
  saveFilters: PropTypes.func,
  removeFilter: PropTypes.func,
};

FilterContainer.defaultProps = {};

export default FilterContainer;
