import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { StyledEditor } from "./RichTextEditor.styled";
import { FORMATS, TOOLBAR } from "constants/richTextEditorConstants";

const modules = {
  toolbar: [
    [{ size: [] }],
    [{ font: [] }],
    [{ color: [] }, { background: [] }],
    [TOOLBAR.BOLD, TOOLBAR.ITALIC, TOOLBAR.UNDERLINE, TOOLBAR.STRIKE],
    [
      { align: "" },
      { align: TOOLBAR.CENTER },
      { align: TOOLBAR.RIGHT },
      { align: TOOLBAR.JUSTIFY },
    ],
    [{ list: TOOLBAR.ORDERED }, { list: TOOLBAR.BULLET }],
    [TOOLBAR.LINK],
  ],
};

const formats = [
  FORMATS.SIZE,
  FORMATS.FONT,
  FORMATS.COLOR,
  FORMATS.BACKGROUND,
  TOOLBAR.BOLD,
  TOOLBAR.ITALIC,
  TOOLBAR.UNDERLINE,
  TOOLBAR.STRIKE,
  TOOLBAR.ALIGN,
  TOOLBAR.ORDERED,
  TOOLBAR.BULLET,
  TOOLBAR.LINK,
];

const RichTextEditor = ({ value, onChange }) => {
  const quillRef = useRef(null);
  const [selectedFont, setSelectedFont] = useState("sans-serif");

  const handleChange = (content) => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const currentFont = editor.getFormat()?.font || "sans-serif";
      setSelectedFont(currentFont);
    }
    onChange?.(content);
  };

  return (
    <StyledEditor font={selectedFont}>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        ref={quillRef}
      />
    </StyledEditor>
  );
};

RichTextEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default RichTextEditor;
