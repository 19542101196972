import {
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Collapse,
  Box,
} from "@mui/material";
import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";

export const StyledTableBody = styled(TableBody)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  width: 100%;
  height: 100%;
`;
export const StyledTableRow = styled(TableRow)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 66px;
  border: 1px solid ${(props) => props?.theme?.colors?.rowBorderColor};
  border-radius: 12px;
  background-color: ${(props) => props?.theme?.colors?.primaryLightBlue};
  height: 32px;
`;
export const CollapTableRow = styled(StyledTableRow)`
  background-color: ${(props) =>
    props?.isChecked
      ? props?.theme?.colors?.backgroundColor
      : hexToRGB(props?.theme?.colors?.backgroundColor, 0.4)};
  display: flex;
  justify-content: flex-end;
  height: 48px;
  gap: 40px;
  padding-right: 15px;
`;
export const StyledTableCell = styled(TableCell)`
  padding: 0px 0px 0px 15px;
  min-width: ${({ width }) => width || "auto"};
  width: fit-content;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  white-space: nowrap;
  & .MuiTypography-root {
    font-size: 16px;
    display: flex;
    flex-direction: row;
  }
`;

export const LastCell = styled(StyledTableCell)`
  display: flex;
  justify-content: right;
`;

export const StyledTypography = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`;

export const CollapseStyled = styled(Collapse)`
  width: 100%;
`;

export const CollapContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
