import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PropTypes from "prop-types";
import CustomTextFieldStyled from "components/InputFields/CustomFields/CustomTextField.styled";

const DateField = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(props?.value || null);

  const handleChange = (newValue) => {
    setValue(newValue);
    const event = {
      target: {
        name: props.name,
        value: newValue,
      },
    };
    props?.formik?.handleChange(event);
  };

  const dateFormat = props?.dateTime ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy";

  return (
    <Box>
      <CustomFieldLabel label={t(props.label)}></CustomFieldLabel>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {props?.dateTime ? (
          <DateTimePicker
            value={value}
            onChange={handleChange}
            inputFormat={dateFormat}
            renderInput={(params) => (
              <CustomTextFieldStyled
                {...params}
                name={props.name}
                formik={props.formik}
                onBlur={props.formik?.handleBlur}
                helperText={
                  props?.formik?.touched[props.name]
                    ? t(props?.formik?.errors[props.name], props?.formik?.errors[props.name])
                    : ""
                }                
                error={
                  props?.formik?.touched[props.name] &&
                  !!props?.formik?.errors[props.name]
                }
              />
            )}
          />
        ) : (
          <DatePicker
            value={value}
            onChange={handleChange}
            inputFormat={dateFormat}
            renderInput={(params) => (
              <CustomTextFieldStyled
                {...params}
                name={props.name}
                formik={props.formik}
                onBlur={props.formik?.handleBlur}
                helperText={
                  props?.formik?.touched[props.name]
                    ? t(props?.formik?.errors[props.name], props?.formik?.errors[props.name])
                    : ""
                }                
                error={
                  props?.formik?.touched[props.name] &&
                  !!props?.formik?.errors[props.name]
                }
              />
            )}
          />
        )}
      </LocalizationProvider>
    </Box>
  );
};

DateField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  formik: PropTypes.any,
  value: PropTypes.any,
  dateTime: PropTypes.bool,
};

DateField.defaultProps = {
  label: "superAdmin.table.licenses.startDate",
  name: "startDate",
  dateTime: false,
};

export default DateField;
