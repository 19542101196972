import React from "react";
import PropTypes from "prop-types";
import {
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  TableTitle,
  StyledTableRowWorkingHours,
} from "./TableHeadContainer.styled";
import { secondaryThemeColors } from "themes/secondaryTheme/secondaryThemeColors";

const TableHeader = (props) => {
  const numberOfHeaders = props.tableHeaders.length;
  const RowComponent = props.tableAlignmentLeft
    ? StyledTableRowWorkingHours
    : StyledTableRow;
  return (
    <StyledTableHead>
      <RowComponent>
        {props.tableHeaders.map((col, index) => {
          const isLastColumn = index === numberOfHeaders - 1;
          return (
            <StyledTableCell
              key={col.field}
              numberOfHeaders={numberOfHeaders}
              isLastColumn={isLastColumn}
              tableAlignmentLeft={props.tableAlignmentLeft}
            >
              <TableTitle
                color={secondaryThemeColors.leftAuthContentBackground}
              >
                {props.t(col.headerName)}
              </TableTitle>
            </StyledTableCell>
          );
        })}
      </RowComponent>
    </StyledTableHead>
  );
};

TableHeader.propTypes = {
  t: PropTypes.any,
  tableHeaders: PropTypes.any,
  tableAlignmentLeft: PropTypes.bool,
};

TableHeader.defaultProps = {
  tableAlignmentLeft: false,
};

export default TableHeader;
