import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { VARIANT } from "constants/buttonConstants";
import { unsetShownModal } from "features/modal/modalSlice";
import {
  ActionButton,
  ActionsContainer,
  CancelActionButton,
  LowerContainer,
  Modal,
  Title,
  UpperContainer,
} from "./RedirectionForMakingAppointmentModal.styled";
import {
  CloseIcon,
  TitleContainer,
} from "components/SuperAdmin/ServiceCategoryEditAddModal/ServiceCategoryEditAddModal.styled";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";

const RedirectionForMakingAppointmentModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickLogin = () => {
    navigate(PAGES.LOGIN.route);
    dispatch(unsetShownModal());
  };
  const handleClickRegister = () => {
    navigate(PAGES.REGISTER_USER.route);
    dispatch(unsetShownModal());
  };
  return (
    <Modal>
      <UpperContainer>
        <TitleContainer>
          <Title>
            {t("scheduleAppointment.RedirectToLoginOrRegisterTitle")}
          </Title>
          <CloseIcon onClick={() => dispatch(unsetShownModal())} />
        </TitleContainer>
      </UpperContainer>
      <LowerContainer>
        <ActionsContainer>
          <CancelActionButton
            variant={VARIANT.OUTLINED}
            onClick={() => dispatch(unsetShownModal())}
            value={t("common.cancel")}
          />
          <ActionButton onClick={handleClickLogin} value={t("login.logIn")} />
          <ActionButton
            onClick={handleClickRegister}
            value={t("login.signUp")}
          />
        </ActionsContainer>
      </LowerContainer>
    </Modal>
  );
};

RedirectionForMakingAppointmentModal.propTypes = {
  data: PropTypes.object,
  clientId: PropTypes.any,
};

export default RedirectionForMakingAppointmentModal;
