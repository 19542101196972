export const MyAppointmentsConstants = {
  UserAppointments: {
    name: "UserAppointments",
    label: "appointments.UserAppointmentsConstant",
  },
  ClientEmployee: {
    name: "ClientEmployee",
    label: "appointments.ClientEmployeeAppointmentsConstants",
  },
  WishList: {
    name: "WishList",
    label: "appointments.WishListAppointmentsConstant",
  },
};
