import React from "react";
import PropTypes from "prop-types";
import {
  DateLabel,
  LeftArrowStyled,
  NavButton,
  RightArrowStyled,
  ToolbarContainer,
  ViewButton,
  ViewButtonGroup,
} from "./CustomToolbar.styled";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import { srLatn, enUS } from "date-fns/locale";
import { authScopeStringGetHelper } from "util/authScopeHelpers";
import { LANGUAGE } from "constants/localStorage";

const CustomToolbar = (props) => {
  const { t } = useTranslation();
  const { label, onNavigate, onView, view, date } = props;
  let lang = authScopeStringGetHelper(LANGUAGE);
  lang = lang === "sr-Latn" ? srLatn : enUS;

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const capitalizeMonth = (dateString) => {
    const parts = dateString.split(" ");
    if (lang === srLatn) {
      parts[0] = parts[0].charAt(0).toUpperCase() + parts[0].slice(1).toLowerCase();
    }
    return parts.join(" ");
  };

  const getFormattedLabel = () => {
    const currentDate = new Date(date);

    switch (view) {
      case "day": {
        const formattedDate = format(currentDate, "EEEE, MMMM dd, yyyy", {
          locale: lang,
        });
        if (lang === srLatn) {
          const [dayOfWeek, month, ...rest] = formattedDate.split(", ");
          const capitalizedDayOfWeek =
            dayOfWeek.charAt(0).toUpperCase() +
            dayOfWeek.slice(1).toLowerCase();
          const capitalizedMonth =
            month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();
          return `${capitalizedDayOfWeek}, ${capitalizedMonth}, ${rest.join(
            ", "
          )}`;
        }

        return formattedDate;
      }
      case "week": {
        const startOfWeekDate = startOfWeek(currentDate, { locale: lang });
        const endOfWeekDate = endOfWeek(currentDate, { locale: lang });

        const formattedStartDate = format(startOfWeekDate, "MMMM dd", {
          locale: lang,
        });
        const formattedEndDate = format(endOfWeekDate, "dd, yyyy", {
          locale: lang,
        });

        return `${capitalizeFirstLetter(
          formattedStartDate
        )} – ${formattedEndDate}`;
      }
      case "month": {
        const monthStart = startOfMonth(currentDate);
        const monthEnd = endOfMonth(currentDate);
        return `${capitalizeFirstLetter(
          format(monthStart, "MMMM dd", { locale: lang })
        )} - ${format(monthEnd, "dd, yyyy", { locale: lang })}`;
      }
      case "agenda": {
        const endDate = new Date(currentDate);
        endDate.setDate(endDate.getDate() + 31);
        const formattedStartDate = format(currentDate, "MMMM dd", {
          locale: lang,
        });
        const formattedEndDate = format(endDate, "MMMM dd, yyyy", {
          locale: lang,
        });
        const capitalizedStartDate = capitalizeMonth(formattedStartDate);
        const capitalizedEndDate = capitalizeMonth(formattedEndDate);

        return `${capitalizedStartDate} - ${capitalizedEndDate}`;
      }
      default:
        return label;
    }
  };

  return (
    <ToolbarContainer>
      <Box display="flex" alignItems="center">
        <NavButton onClick={() => onNavigate("PREV")}>
          <LeftArrowStyled />
        </NavButton>
        <DateLabel>{getFormattedLabel()}</DateLabel>
        <NavButton onClick={() => onNavigate("NEXT")}>
          <RightArrowStyled />
        </NavButton>
      </Box>
      <ViewButtonGroup>
        <ViewButton
          className={view === "day" ? "active" : ""}
          onClick={() => onView("day")}
        >
          {t("calendar.day")}
        </ViewButton>
        <ViewButton
          className={view === "week" ? "active" : ""}
          onClick={() => onView("week")}
        >
          {t("calendar.week")}
        </ViewButton>
        <ViewButton
          className={view === "month" ? "active" : ""}
          onClick={() => onView("month")}
        >
          {t("calendar.month")}
        </ViewButton>
        <ViewButton
          className={view === "agenda" ? "active" : ""}
          onClick={() => onView("agenda")}
        >
          {t("calendar.agenda")}
        </ViewButton>
      </ViewButtonGroup>
    </ToolbarContainer>
  );
};

CustomToolbar.propTypes = {
  label: PropTypes.string.isRequired,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
};

export default CustomToolbar;
