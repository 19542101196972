import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import { Box, MenuItem } from "@mui/material";
import CustomSelectFieldStyled, {
  DownArrowStyled,
  menuProps,
} from "components/InputFields/CustomFields/CustomSelectField.styled";

const ResourceSelectField = (props) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    props.onChange(event.target.value, props.label);
  };
  useEffect(() => {
    if (props.selectedFirst != null && props.defaultValue != null) {
      setSelectedValue({ id: null, name: t(props.defaultValue) });
    }
  }, [props.selectedFirst, props.defaultValue]);

  return (
    <Box>
      <CustomFieldLabel label={t(props.label)} />
      <CustomSelectFieldStyled
        name={props.name}
        value={selectedValue}
        displayEmpty
        onChange={handleChange}
        renderValue={(selected) => {
          if (!selected) {
            return <label>{t(props.placeholder)}</label>;
          }
          return selected?.name;
        }}
        IconComponent={DownArrowStyled}
        MenuProps={menuProps}
        selectedValueFlag={selectedValue}
      >
        <MenuItem key={null} value={{ id: null, name: t(props.defaultValue) }}>
          {t(props.defaultValue)}
        </MenuItem>
        {props.items.map?.((item) => {
          return (
            <MenuItem key={item.id} value={item}>
              {item.name}
            </MenuItem>
          );
        })}
      </CustomSelectFieldStyled>
    </Box>
  );
};

ResourceSelectField.propTypes = {
  index: PropTypes.number,
  onChange: PropTypes.func,
  useQuery: PropTypes.func,
  mapDataFunction: PropTypes.func,
  value: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  items: PropTypes.array,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  selectedFirst: PropTypes.bool,
};

ResourceSelectField.defaultProps = {
  defaultValue: "scheduleAppointment.firstAvailable",
};

export default ResourceSelectField;
