import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  AvailableSlotsContainer,
  LeftContainer,
  MainContainer,
  ParametersContainer,
  RightContainer,
} from "./SchedulingAppointmentContent.styled";
import AvailableSlotsContent from "./AvailableSlotsContent/AvailableSlotsContent";
import ChosenSlotInfo from "./ChosenSlotInfo/ChosenSlotInfo";
import ServiceParamesContent from "./ServiceParamesContent/ServiceParamesContent";
import {
  useGetAvailableTimeSlotsQuery,
  useScheduleAppointmentMutation,
} from "features/appointmentSlice/appointmentApiSlice";
import { formatDate_T_Time, getStartAndEndOfWeek } from "util/dateHelpers";
import { makeToastMessage } from "util/toastMessage";
import { useTranslation } from "react-i18next";
import toastNotificationType from "constants/toastNotificationType";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import { useNavigate, useParams } from "react-router-dom";
import { PAGES } from "constants/pages";
import {
  setAddClientToFavModal,
  setRedirectionForAppointmentModal,
} from "features/modal/modalSlice";
import { setMakeWishForAppointmentModal } from "features/modal/modalSlice";
import { MakeWishForAppointmentButton } from "./ServiceParamesContent/ServiceParamesContent.styled";

const SchedulingAppointmentContent = (props) => {
  const { t } = useTranslation();
  const user = useSelector(selectCurrentUser);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [scheduleAppointment] = useScheduleAppointmentMutation();
  const {
    data: timeSlotsResponse,
    isLoading,
    refetch,
  } = useGetAvailableTimeSlotsQuery(data);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [selectedResources, setSelectedResources] = useState({});
  const [chosenTimeSlot, setChosenTimeSlot] = useState(null);
  const [note, setNote] = useState(null);
  const [startAndEndOfWeek, setStartAndEndOfWeek] = useState(
    getStartAndEndOfWeek()
  );
  const timeSlots = isLoading ? [] : timeSlotsResponse?.data?.timeSlots ?? [];
  const onSelectAttribute = (attributeId) => {
    setSelectedAttributes((oldArray) => [...oldArray, attributeId]);
  };

  const onDeselectAttribute = (attribute) => {
    setSelectedAttributes((oldArray) => [
      ...oldArray.filter((item) => item !== attribute),
    ]);
  };

  const onSelectResource = (resource, key) => {
    setSelectedResources((oldDictionary) => {
      return { ...oldDictionary, [`${key}`]: resource };
    });
  };

  const onChangePeriod = (newStartAndEndOfWeek) => {
    setStartAndEndOfWeek(newStartAndEndOfWeek);
    let adjustedStartDate = newStartAndEndOfWeek.startOfWeek;
    if (adjustedStartDate <= new Date()) {
      const today = new Date();
      const dayOfWeek = today.getDay();

      if (dayOfWeek !== 1) {
        adjustedStartDate = today;
        adjustedStartDate.setHours(
          today.getUTCHours(),
          today.getUTCMinutes(),
          today.getUTCSeconds(),
          today.getUTCMilliseconds()
        );
      }
    }
    setData((oldValue) => {
      return {
        ...oldValue,
        startDate: formatDate_T_Time(adjustedStartDate),
        endDate: formatDate_T_Time(newStartAndEndOfWeek.endOfWeek),
      };
    });
    refetch();
    setChosenTimeSlot(null);
  };

  const { clientId, clientServiceId } = useParams();
  const searchForAppointments = async () => {
    let adjustedStartDate = startAndEndOfWeek.startOfWeek;
    if (adjustedStartDate <= new Date()) {
      const today = new Date();
      const dayOfWeek = today.getDay();

      if (dayOfWeek !== 1) {
        adjustedStartDate = today;
        adjustedStartDate.setHours(
          today.getUTCHours(),
          today.getUTCMinutes(),
          today.getUTCSeconds(),
          today.getUTCMilliseconds()
        );
      }
    }

    setData({
      startDate: formatDate_T_Time(adjustedStartDate),
      endDate: formatDate_T_Time(startAndEndOfWeek.endOfWeek),
      clientId: clientId,
      clientServiceId: clientServiceId,
      clientServiceAttributesIds: selectedAttributes?.map(
        (attribute) => attribute.id
      ),
      userSelectedResources: Object.fromEntries(
        Object.entries(selectedResources)?.map(([key, resource]) => [
          key,
          resource.id,
        ])
      ),
    });

    refetch();
    setChosenTimeSlot(null);
  };

  useEffect(() => {
    searchForAppointments();
  }, [selectedAttributes]);

  const onSchedule = async () => {
    if (!user) {
      dispatch(setRedirectionForAppointmentModal());
    }

    const data = {
      fromTimeUtc: chosenTimeSlot.startTime,
      toTimeUtc: chosenTimeSlot.endTime,
      note: note,
      clientId: clientId,
      userId: user.id,
      clientServiceId: clientServiceId,
      clientResourceIds: Object.values(chosenTimeSlot.resources)?.map(
        (value) => value.resource.id
      ),
      clientServiceAttributeIds: selectedAttributes?.map(
        (attribute) => attribute.id
      ),
    };

    const response = await scheduleAppointment({
      userId: user.id,
      data,
    });
    if (response.error) {
      response.error.data.errors?.map((error) => {
        switch (error.code) {
          default:
            alert(error.message);
            break;
        }
      });
    } else {
      let userSubscribed = false;
      for (let i = 0; i < props?.subscribedUsers?.length; i++) {
        if (
          props?.subscribedUsers[i].user.id === user.id &&
          props?.subscribedUsers[i].endDateUtc === null
        ) {
          userSubscribed = !userSubscribed;
        }
      }
      if (!userSubscribed && user?.clientId != clientId) {
        dispatch(
          setAddClientToFavModal({
            userId: user.id,
            clientId: props?.clientService?.client?.id,
          })
        );
      }
      makeToastMessage(
        t("scheduleAppointment.successfullyScheduledAppointment"),
        toastNotificationType.SUCCESS
      );
      refetch();
      navigate(PAGES.APPOINTMENTS.route);
    }
  };

  return (
    <MainContainer>
      <LeftContainer>
        <ParametersContainer>
          <ServiceParamesContent
            onSelectAttribute={onSelectAttribute}
            onDeselectAttribute={onDeselectAttribute}
            onSelectResource={onSelectResource}
            searchForAppointments={searchForAppointments}
            attributes={props?.clientService?.attributes}
            requiredResourceTypes={props?.clientService?.requiredResourceTypes}
            clientService={props?.clientService}
          />
        </ParametersContainer>
        <AvailableSlotsContainer>
          <AvailableSlotsContent
            startAndEndOfWeek={startAndEndOfWeek}
            onChangePeriod={(newValue) => onChangePeriod(newValue)}
            timeSlots={timeSlots}
            clientService={props?.clientService}
            passSelectedTimeSlot={(newValue) => setChosenTimeSlot(newValue)}
            selectedAttributes={selectedAttributes}
          />
        </AvailableSlotsContainer>
      </LeftContainer>
      {chosenTimeSlot ? (
        <RightContainer>
          <ChosenSlotInfo
            chosenSlot={chosenTimeSlot}
            clientService={props?.clientService}
            selectedAttributes={selectedAttributes}
            setNote={(newNote) => setNote(newNote)}
            onSchedule={onSchedule}
          />
        </RightContainer>
      ) : (
        <MakeWishForAppointmentButton
          onClick={() =>
            dispatch(
              setMakeWishForAppointmentModal({
                clientService: props?.clientService,
              })
            )
          }
          value={t("scheduleAppointment.makeWishForAppointment")}
        />
      )}
    </MainContainer>
  );
};

SchedulingAppointmentContent.propTypes = {
  clientService: PropTypes.any,
  subscribedUsers: PropTypes.any,
};

export default SchedulingAppointmentContent;
