import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectModal } from "features/modal/modalSlice";
import ServiceCategoryEditAddModal from "components/SuperAdmin/ServiceCategoryEditAddModal/ServiceCategoryEditAddModal";
import SentCodeModal from "./SentCodeModal/SentCodeModal";
import FinishedRegistrationModal from "components/RegistrationContent/FinishedRegistrationModal/FinishedRegistrationModal";
import ChangeUserStatusModal from "components/SuperAdmin/ChangeUserStatusModal/ChangeUserStatusModal";
import LicenseEditAddModal from "components/SuperAdmin/LicenseAddEditModal/LicenceAddEditModal";
import { LicenseDeleteModal } from "components/SuperAdmin/LicenseDeleteModal/LicenseDeleteModal";
import DeleteResourceModal from "components/ClientEditTab/ClientContentTab/TabContent/Resource/DeleteResourceModal/DeleteResourceModal";
import AddEditResourceModal from "components/ClientEditTab/ClientContentTab/TabContent/Resource/AddEditResourceModal/AddEditResourceModal";
import SetWorkingHoursModal from "components/ScheduleEditor/SetWorkingHoursModal/SetWorkingHoursModal";
import ClientServiceAttributeModal from "./ClientServiceModals/ClientServiceAttributeModal";
import ClientServiceResourceGroupModal from "./ClientServiceModals/ClientServiceResourceGroupModal";
import { ClientServiceDeleteModal } from "./ClientServiceModals/ClientServiceDeleteModal";
import UnsavedAlertModal from "components/UnsavedAlertModal/UnsavedAlertModal";
import { Overlay } from "./Modal.styled";
import FullImageModal from "components/ImageGallery/FullImageModal/FullImageModal";
import CancelAppointmentModal from "components/Appointment/CancelAppointmentModal/CancelAppointmentModal";
import AddClientsToFavoritesModal from "components/AddClientsToFavoritesModal/AddClientsToFavoritesModal";
import AddAppointmentModal from "components/ClientEditTab/ClientContentTab/TabContent/Calendar/AddAppointmentModal/AddAppointmentModal";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import MakeWishForAppointmentModal from "components/SchedulingAppointmentContent/MakeWishForAppointmentModal/MakeWishForAppointmentModal";
import { FilterContainerStyled } from "components/Filter/FilterContainer.styled";
import RedirectionForMakingAppointmentModal from "./RedirectionForMakingAppointmentModal/RedirectionForMakingAppointmentModal";
import CalendarInformationAndCancellationModal from "components/ClientEditTab/ClientContentTab/TabContent/Calendar/CalendarComponent/CalendarInformationAndCancellationModal/CalendarInformationAndCancellationModal";

const Modal = () => {
  const modal = useSelector(selectModal);
  const isAnyModalOpen = useMemo(() => {
    return Object.values(modal).some((value) => value === true);
  }, [modal]);

  return (
    <Overlay isVisible={isAnyModalOpen} isFilterModal={modal.filterModal}>
      {modal.addAppointmentModal && (
        <AddAppointmentModal {...modal.addAppointmentModalProps} />
      )}
      {modal.serviceCategoryModal && (
        <ServiceCategoryEditAddModal {...modal.serviceCategoryModalProps} />
      )}
      {modal.redirectionForMakingAppointmentModal && (
        <RedirectionForMakingAppointmentModal />
      )}
      {modal.makeWishForAppointmentModal && (
        <MakeWishForAppointmentModal
          {...modal.makeWishForAppointmentModalProps}
        />
      )}
      {modal.cancelAppointmentModal && (
        <CancelAppointmentModal {...modal.cancelAppointmentModalProps} />
      )}

      {modal.finishedRegistrationModal && (
        <FinishedRegistrationModal {...modal.finishedRegistrationModalProps} />
      )}
      {modal.sentCodeModal && <SentCodeModal {...modal.sentCodeModalProps} />}
      {modal.changeUserStatusModal && (
        <ChangeUserStatusModal {...modal.changeUserStatusModalProps} />
      )}
      {modal.clientServiceAttributeModal && (
        <ClientServiceAttributeModal
          {...modal.clientServiceAttributeModalProps}
        />
      )}
      {modal.clientServiceResourceModal && (
        <ClientServiceResourceGroupModal
          {...modal.clientServiceResourceModalProps}
        />
      )}
      {modal.deleteResourceModal && (
        <DeleteResourceModal {...modal.deleteResourceModalProps} />
      )}
      {modal.addEditResourceModal && (
        <AddEditResourceModal {...modal.addEditResourceModalProps} />
      )}
      {modal.workingTimeModal && (
        <SetWorkingHoursModal {...modal.workingTimeModalProps} />
      )}
      {modal.clientServiceDeleteModal && (
        <ClientServiceDeleteModal {...modal.clientServiceDeleteModalProps} />
      )}
      {modal.changeLicenceModal && (
        <LicenseEditAddModal {...modal.changeLicenceModalProps} />
      )}
      {modal.deleteLicenceModal && (
        <LicenseDeleteModal {...modal.deleteLicenceModalProps} />
      )}
      {modal.unsavedAlertModal && (
        <UnsavedAlertModal {...modal.unsavedAlertModalProps} />
      )}
      {modal.fullImageModal && (
        <FullImageModal {...modal.fullImageModalProps} />
      )}
      {modal.addClientToFavModal && (
        <AddClientsToFavoritesModal {...modal.addClientToFavModalProps} />
      )}
      {modal.calendarInformationAndCancellationModal && (
        <CalendarInformationAndCancellationModal
          {...modal.calendarInformationAndCancellationModalProps}
        />
      )}
      {modal.loadingModal && <LoadingSpinner />}
      {modal.filterModal && <FilterContainerStyled />}
    </Overlay>
  );
};

export default Modal;
