export const primaryBreakpoints = {
  down: (size) => {
    const sizes = {
      xs: 400,
      sm: 750,
      md: 1060,
      lg: 1280,
      xl: 1920,
    };

    return `
      @media (max-width: ${sizes[size]}px), 
             (max-height: ${sizes[size]}px) and (orientation: landscape),
             (max-width: ${sizes[size]}px) and (orientation: portrait) {
    `;
  },
};
