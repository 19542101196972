import React from "react";
import PropTypes from "prop-types";
import {
  SearchForAppointmentsButton,
  ServiceParamesContentStyled,
} from "./ServiceParamesContent.styled";
import AttributesContent from "./AttributesContent/AttributesContent";
import ResourcesContent from "./ResourcesContent/ResourcesContent";
import { useTranslation } from "react-i18next";

const ServiceParamesContent = (props) => {
  const { t } = useTranslation();
  return (
    <ServiceParamesContentStyled>
      <AttributesContent
        attributes={props.attributes}
        onSelectAttribute={props.onSelectAttribute}
        onDeselectAttribute={props.onDeselectAttribute}
      />
      <ResourcesContent
        requiredResourceTypes={props.requiredResourceTypes}
        onSelectResource={props.onSelectResource}
      />
      <SearchForAppointmentsButton
        onClick={props.searchForAppointments}
        value={t("scheduleAppointment.searchForAppointments")}
      />
    </ServiceParamesContentStyled>
  );
};

ServiceParamesContent.propTypes = {
  attributes: PropTypes.any,
  requiredResourceTypes: PropTypes.any,
  onSelectAttribute: PropTypes.any,
  onDeselectAttribute: PropTypes.any,
  onSelectResource: PropTypes.any,
  searchForAppointments: PropTypes.any,
  clientService: PropTypes.any,
};

export default ServiceParamesContent;
