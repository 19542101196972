import styled from "@emotion/styled";

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledText = styled.p`
  font-size: 24px;
`;
