import styled from "styled-components";

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  gap: 20px;
  overflow-y: auto;
  padding: 20px;
  height: 100%;
`;
