import React, { useEffect, useState } from "react";
import { AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  UpperContainer,
  AllowBookingWrapper,
  TimeSelect,
  Label,
  TypographyStyled,
  AccordionStyled,
} from "./ScheduleEditor.styled";
import ClientSwitch from "components/ClientSwitch/ClientSwitch";
import getTableColumns from "constants/tableConstants/WorkingHoursTableConstants";
import TableHeader from "components/Table/TableHeadContainer";
import TableBodyContainer from "components/Table/TableBodyContainer";
import Status from "components/Status/Status";
import TimeSlot from "./TimeSlot";
import {
  setWorkingHoursModal,
  unsetShownModal,
} from "features/modal/modalSlice";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import timeParams from "initialValues/timeInitalValue";
import { useValidationSchema } from "validations/workingHoursValidation";
import {
  addWorkingHour,
  removeAllWorkingHour,
  removeWorkingHour,
  setAppointmentEnabled,
  setAppointmentStartTime,
  setCancellationTimeframe,
  toggleFlag,
} from "features/clients/clientSlice";
import { mapWorkingHours } from "util/mapWorkingHours";
import { makeToastMessage } from "util/toastMessage";
import inputTypes from "constants/inputTypes";
import CustomTextField from "components/InputFields/CustomFields/CustomTextField";

const ScheduleEditor = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const timeOptions = [15, 30, 60];
  const [timeSlot, setTimeSlot] = useState(timeOptions[0]);
  const [isAllowed, setIsAllowed] = useState(props.publicAppointmentEnabled);
  const [workingHours, setWorkingHours] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const enumToTimeMap = {
    0: 60,
    1: 30,
    2: 15,
  };

  useEffect(() => {
    dispatch(
      setCancellationTimeframe(props.timeBeforeCancellationAllowedInHours)
    );
    dispatch(setAppointmentEnabled(props?.publicAppointmentEnabled));
    setIsAllowed(props.publicAppointmentEnabled);
    const mappedHours = mapWorkingHours(props.workingDaysHours);
    dispatch(removeAllWorkingHour());
    mappedHours.forEach(({ day, hours }) => {
      hours.forEach(({ fromTimeUtc, toTimeUtc }) => {
        dispatch(
          addWorkingHour({ day, startTime: fromTimeUtc, endTime: toTimeUtc })
        );
      });
    });
    setWorkingHours(mappedHours);

    const initialTimeSlot =
      enumToTimeMap[props.appointmentStartTimeInMinutes] || 60;
    setTimeSlot(initialTimeSlot);
    dispatch(setAppointmentStartTime(initialTimeSlot === 15 ? 2 : initialTimeSlot === 30 ? 1 : 0));
  }, [props.workingDaysHours, props.appointmentStartTimeInMinutes]);

  const handleChange = () => {
    setExpanded((prev) => !prev);
  };

  const handleSubmit = () => {
    const { startTime, endTime } = formik.values;
    props.onSaveTime(props.day, startTime, endTime);
    dispatch(unsetShownModal());
  };

  const doesOverlap = (existingHours, newHour) => {
    const newStart = new Date(newHour.startTime);
    const newEnd = new Date(newHour.endTime);

    return existingHours.some(({ range }) => {
      const [from, to] = range.split(" - ").map((time) => {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
      });

      const existingStart = from;
      const existingEnd = to;

      const newStartMinutes = newStart.getHours() * 60 + newStart.getMinutes();
      const newEndMinutes = newEnd.getHours() * 60 + newEnd.getMinutes();

      return newStartMinutes < existingEnd && newEndMinutes > existingStart;
    });
  };

  const handleAddWorkingHour = (newWorkingHour) => {
    const formatTime = (time) => {
      const date = new Date(time);
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${hours}:${minutes}`;
    };

    setWorkingHours((prevHours) => {
      const existingEntry = prevHours.find(
        (entry) => entry.day === newWorkingHour.day
      );
      if (existingEntry && doesOverlap(existingEntry.hours, newWorkingHour)) {
        makeToastMessage(t("workingHours.timeOverlap"), "warning");
        return prevHours;
      }

      dispatch(addWorkingHour(newWorkingHour));
      dispatch(toggleFlag());

      return prevHours.map((entry) => {
        if (entry.day === newWorkingHour.day) {
          const updatedHours = [
            ...entry.hours,
            {
              fromTimeUtc: newWorkingHour.startTime,
              toTimeUtc: newWorkingHour.endTime,
              range: `${formatTime(newWorkingHour.startTime)} - ${formatTime(
                newWorkingHour.endTime
              )}`,
            },
          ];
          updatedHours.sort(
            (a, b) => new Date(a.fromTimeUtc) - new Date(b.fromTimeUtc)
          );
          return {
            ...entry,
            hours: updatedHours,
          };
        }
        return entry;
      });
    });
  };

  const handleRemove = (day, fromTimeUtc, toTimeUtc) => {
    dispatch(
      removeWorkingHour({ day, startTime: fromTimeUtc, endTime: toTimeUtc })
    );
    dispatch(toggleFlag());
    setWorkingHours((prevHours) => {
      return prevHours.map((entry) => {
        if (entry.day !== day) return entry;
        const updatedHours = entry.hours.filter(
          (hour) =>
            hour.fromTimeUtc !== fromTimeUtc || hour.toTimeUtc !== toTimeUtc
        );

        return {
          ...entry,
          hours: updatedHours,
        };
      });
    });
  };

  const tableHeaders = getTableColumns(t);
  const workingDays = tableHeaders[0].workingDays;

  const rows = workingHours.map((entry, index) => {
    const sortedHours = [...entry.hours].sort(
      (a, b) => new Date(a.fromTimeUtc) - new Date(b.fromTimeUtc)
    );
    return {
      id: index + 1,
      workingDay: workingDays[entry.day - 1],
      workingHours:
        sortedHours.length > 0 ? (
          sortedHours.map(({ fromTimeUtc, toTimeUtc, range }) => (
            <TimeSlot
              key={`${entry.day}-${fromTimeUtc}-${toTimeUtc}`}
              hours={range}
              onRemove={() => handleRemove(entry.day, fromTimeUtc, toTimeUtc)}
            />
          ))
        ) : (
          <Status
            isActive={false}
            activeTextKey="workingHours.notWorking"
            inactiveTextKey="workingHours.notWorking"
            showDot={false}
          />
        ),
    };
  });

  const toggleBookingAllowed = () => {
    const newAllowedState = !isAllowed;
    setIsAllowed(newAllowedState);
    dispatch(setAppointmentEnabled(newAllowedState));
    dispatch(toggleFlag());
  };

  const handleTimeChange = (e) => {
    const selectedTime = parseInt(e.target.value, 10);
    setTimeSlot(selectedTime);
    let enumValue = selectedTime === 15 ? 2 : selectedTime === 30 ? 1 : 0;
    dispatch(setAppointmentStartTime(enumValue));
    dispatch(toggleFlag());
  };

  const handleCancellationTimeframe = (e) => {
    formik.handleChange(e);
    dispatch(setCancellationTimeframe(e.target.value));
  };

  const formik = useFormik({
    initialValues: timeParams,
    validationSchema: useValidationSchema(),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <AccordionStyled expanded={expanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <TypographyStyled>
          {t("workingHours.workingHoursTitle")}
        </TypographyStyled>
      </AccordionSummary>
      <AccordionDetails>
        <UpperContainer>
          <AllowBookingWrapper>
            <Label allowed>{t("workingHours.bookingAllowed")}:</Label>
            <ClientSwitch allowed={isAllowed} onChange={toggleBookingAllowed} />
          </AllowBookingWrapper>
          <Label>{t("workingHours.appointmentBeginning")}</Label>
          <TimeSelect value={timeSlot} onChange={handleTimeChange}>
            {timeOptions.map((option) => (
              <option key={option} value={option}>
                {option} min
              </option>
            ))}
          </TimeSelect>
          <Label>{t("workingHours.timeBeforeCancellationAllowed")}</Label>
          <CustomTextField
            type={inputTypes.NUMBER}
            name="timeBeforeCancellationAllowedInHours"
            value={
              formik.values.timeBeforeCancellationAllowedInHours ||
              props?.timeBeforeCancellationAllowedInHours
            }
            onChange={(e) => {
              handleCancellationTimeframe(e);
              dispatch(toggleFlag());
            }}
            onBlur={formik.handleBlur}
            formik={formik}
            minNumValue={1}
            width="90px"
            height="30px"
            required
            showHours={true}
          />
        </UpperContainer>
        <TableHeader
          tableHeaders={tableHeaders}
          t={t}
          tableAlignmentLeft={true}
        />
        <TableBodyContainer
          rows={rows}
          tableHeaders={tableHeaders}
          rowBtnClick={(day) => {
            dispatch(
              setWorkingHoursModal({
                day: day.id,
                onAddWorkingHour: handleAddWorkingHour,
              })
            );
          }}
          tableAlignmentLeft={true}
          setMinHeight={false}
          numberOfCharactersPerCell={30}
        />
      </AccordionDetails>
    </AccordionStyled>
  );
};

ScheduleEditor.propTypes = {
  formik: PropTypes.any,
  onSaveTime: PropTypes.func,
  day: PropTypes.number,
  workingDaysHours: PropTypes.array,
  appointmentStartTimeInMinutes: PropTypes.number,
  publicAppointmentEnabled: PropTypes.bool,
  timeBeforeCancellationAllowedInHours: PropTypes.number,
};

export default ScheduleEditor;
