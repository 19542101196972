import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as LandingPageCalendarImage } from "../../../assets/images/svg/landing-page-calendar-image.svg";
import { ReactComponent as LandingPageCalendarImageSecond } from "../../../assets/images/svg/landing-page-calendar-image-second.svg";
import { ReactComponent as LandingPageCalendarImageSecondNotLogged } from "../../../assets/images/svg/landing-page-calendar-image-second-notlogged.svg";
import { RightArrowStyled } from "components/ClientPanelForUsers/GalleryContent/GalleryContent.styled";
import { Typography } from "@mui/material";

export const CardSectionContainer = styled.div`
  display: flex;
  gap: 24px;
`;
export const CardFirstStyled = styled.div`
  display: flex;
  width: 57%;
  background-color: ${(props) => props?.theme?.colors?.textColor2};
  border: 1px solid ${(props) => props?.theme?.colors?.textBlack};
  border-radius: 12px;
  padding: 24px;
  gap: 24px;
`;

export const CardSecondStyled = styled(Link)`
  display: flex;
  width: 43%;
  background-color: ${(props) => props?.theme?.colors?.purpleBoxBackgournd};
  border: 1px solid ${(props) => props?.theme?.colors?.secondaryColor};
  border-radius: 12px;
  padding: 24px;
  gap: 24px;
  text-decoration: none;
  cursor: pointer;
`;

export const CardImageLeft = styled.img`
  align-self: left;
  min-width: 120px;
  min-height: 140px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 80%;
`;

export const CardFirstTitle = styled(Typography)`
  color: ${(props) => props?.theme?.colors?.white};
  font-size: 24px;
  font-weight: 700;
`;

export const CardFirstText = styled(Typography)`
  color: ${(props) => props?.theme?.colors?.white};
  font-size: 16px;
  font-weight: 400;
`;

export const CardSecondTitle = styled(Typography)`
  color: ${(props) => props?.theme?.colors?.black};
  font-size: 18px;
  font-weight: 700;
`;

export const CardSecondText = styled(Typography)`
  color: ${(props) => props?.theme?.colors?.black};
  font-size: 12px;
  font-weight: 400;
`;

export const ArrowStyled = styled(RightArrowStyled)`
  display: flex;
  align-self: flex-end;
`;

export const CardImageRight = styled.img`
  align-self: left;
  min-width: 85px;
  min-height: 96px;
`;

export const CardImageContainer = styled(CardImageLeft).attrs({
  as: LandingPageCalendarImage,
})``;

export const CardImageContainerSecond = styled(CardImageRight).attrs({
  as: LandingPageCalendarImageSecond,
})``;

export const CardImageContainerSecondNotLogged = styled(CardImageRight).attrs({
  as: LandingPageCalendarImageSecondNotLogged,
})``;
