import styled from "styled-components";
import { TextField } from "@mui/material";
import { hexToRGB } from "util/colorHelper";

const CustomTextFieldStyled = styled(TextField)`
  & .MuiOutlinedInput-root {
    & fieldset {
      border-radius: 12px;
      border-color: ${({ isClient, disabled, theme }) =>
        disabled
          ? theme?.colors?.secondaryColor
          : isClient
          ? theme?.colors?.text3
          : theme?.colors?.text3};
      border-width: "1px";
    }
    &:hover fieldset {
      border-color: ${(props) =>
        props?.disabled ? "" : props?.theme?.colors?.secondaryColor};
    }
    &.Mui-focused fieldset {
      border-color: ${({ isClient, theme }) =>
        isClient ? theme?.colors?.text3 : theme?.colors?.secondaryColor};
      border-width: "2px";
    }
    height: ${(props) => (props?.height ? props?.height : "")};

    ${(props) => props.theme.breakpoints.down("sm")} {
      height: 40px;
    }
  }
  & .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props?.theme?.colors?.errorColor};
  }
  & .MuiFormHelperText-root {
    font-size: 0.75rem;
    font-weight: 400;
    position: absolute;
    top: 100%;
  }
  & .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: ${(props) => props?.theme?.colors?.textColor2};
    background-color: ${(props) =>
      hexToRGB(props?.theme?.colors?.clientPageFormBorder, 0.5)};
    border-radius: 12px;
  }
  & .MuiInputBase-input {
    padding: 8.5px 14px;
    width: 100%;
    height: ${(props) => (props?.height ? props?.height : "")};
    margin-bottom: ${({ isClient }) => (isClient ? "4px" : "0px")};

    ${(props) => props.theme.breakpoints.down("sm")} {
      height: 40px;
    }
  }
  width: ${(props) => (props?.width ? props?.width : "100%")};

  & .MuiOutlinedInput-input::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding-left: 0px;
  }
  max-height: 40px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    max-height: 40px;
  }
`;

export default CustomTextFieldStyled;
