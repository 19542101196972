import * as React from "react";
import { useTranslation, Trans } from "react-i18next";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
  StyledSmallCard,
  StyledButton,
  StyledTypography,
  StyledBrushEffect,
  CheckIcon,
  LogoContainer,
  CardContainer,
  MainContainer,
  AlreadyGotAccount,
  LogLinkStyled,
  StyledBigCardMiddle,
  AlreadyGotAccountContainer,
  Logo,
  StyledNavLink,
} from "./MiddlepageContent.styled";
import { PAGES } from "constants/pages";
import { TEXTVARIANT } from "constants/textConstants";
import { SIZE, VARIANT } from "constants/buttonConstants";
import { StyledLink } from "components/Sidebar/Sidebar.styled";

const MiddlePageContent = () => {
  const { t } = useTranslation();

  return (
    <StyledBigCardMiddle>
      <MainContainer>
        <LogoContainer>
          <StyledLink to={PAGES.BASE.route}>
            <Logo />
          </StyledLink>
        </LogoContainer>
        <CardContainer>
          <StyledSmallCard purpleCard>
            <StyledTypography gutterBottom variant={TEXTVARIANT.H6} titleStyle>
              <Trans
                i18nKey={"middlepage.client.title"}
                components={[<StyledBrushEffect key={0} color />]}
              />
            </StyledTypography>
            <StyledTypography variant={VARIANT.BODY2} textStyle>
              {t("middlepage.client.text")}
            </StyledTypography>
            <StyledNavLink to={PAGES.REGISTER_CLIENT.route}>
              <StyledButton
                variant={VARIANT.CONTAINED}
                size={SIZE.SMALL}
                purpleButton
              >
                {t("middlepage.client.button")} <CheckIcon />
              </StyledButton>
            </StyledNavLink>
          </StyledSmallCard>
          <StyledSmallCard>
            <StyledTypography gutterBottom variant={TEXTVARIANT.H6} titleStyle>
              <Trans
                i18nKey={"middlepage.user.title"}
                components={[<StyledBrushEffect key={0} />]}
              />
            </StyledTypography>
            <StyledTypography variant={VARIANT.BODY2} textStyle>
              {t("middlepage.user.text")}
            </StyledTypography>
            <StyledNavLink to={PAGES.REGISTER_USER.route}>
              <StyledButton variant={VARIANT.CONTAINED} size={SIZE.SMALL}>
                {t("middlepage.user.button")} <CheckIcon />
              </StyledButton>
            </StyledNavLink>
          </StyledSmallCard>
        </CardContainer>
        <AlreadyGotAccountContainer>
          <AlreadyGotAccount>
            {t("middlepage.alreadyGotAccount")}
            <LogLinkStyled
              to={PAGES.LOGIN.route}
              component={NavLink}
              color={(props) => props?.theme?.colors?.leftAuthContentBackground}
            >
              {t("login.logIn")}
            </LogLinkStyled>
          </AlreadyGotAccount>
        </AlreadyGotAccountContainer>
      </MainContainer>
    </StyledBigCardMiddle>
  );
};

MiddlePageContent.propTypes = {
  theme: PropTypes.any,
};

export default MiddlePageContent;
