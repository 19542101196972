import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: ${(props) => props?.theme?.colors?.backgroundColor};
  }
  *:not(.ql-editor *) {
    font-family: Nunito !important;
  }

  .toast-success{
    background-color:${(props) =>
      props?.theme?.colors?.toastSuccessColor} !important;
    color:#000000;
  }
  
  .toast-error{
    background-color:${(props) =>
      props?.theme?.colors?.toastErrorColor} !important;
    color:#000000;
  }

  .toast-warning{
    background-color:${(props) =>
      props?.theme?.colors?.toastWarningColor} !important;
    color:#000000;
  }
`;

export default GlobalStyle;
