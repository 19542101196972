import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useResetPasswordMutation } from "features/forgotPassword/forgotPasswordSlice";
import { PAGES } from "constants/pages";
import { useFormik } from "formik";
import MainLayout from "layouts/MainLayout";
import PasswordField from "components/InputFields/PasswordField";
import { FormikParams } from "constants/formikParams";
import resetPasswordValidation from "validations/resetPasswordValidation";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalSize } from "components/Modals/ModalSize";
import { makeToastMessage } from "util/toastMessage";
import { StyledLink } from "components/Sidebar/Sidebar.styled";
import { Logo } from "components/Header/Header.styled";
import {
  ButtonWrapper,
  ForgotPasswordTitle,
  InputFieldContainer,
  LogTitleContainerStyled,
  LowerContainer,
  ResetPasswordContainer,
  StyledButton,
  TitleAndInputContainer,
} from "./ForgotPassword.styled";

const ResetPassword = () => {
  const [passwordsDoNotMatchError, setPasswordsDoNotMatchError] =
    useState(null);
  const { t } = useTranslation();
  const [useResetPassword] = useResetPasswordMutation();
  const navigate = useNavigate();
  const location = useLocation();

  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  const queryParams = getQueryParams(location.search);
  const id = queryParams.get("id");
  const token = queryParams.get("token");

  const handleSubmit = async () => {
    const data = {
      password: formik.values.password,
      confirmedPassword: formik.values.confirmedPassword,
    };
    if (!id || !token) {
      return;
    }
    const response = await useResetPassword({
      id: id,
      token: token,
      body: data,
    });
    if (response.error) {
      makeToastMessage(response.error.data.errors[0].message, "error");
    } else {
      makeToastMessage(t("forgotPassword.passwordChanged"), "success");
      navigate(PAGES.LOGIN.route);
    }
  };

  const formik = useFormik({
    initialValues: { password: "", confirmedPassword: "" },
    validationSchema: resetPasswordValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const clearPasswordConfirmErrorMessage = () => {
    if (passwordsDoNotMatchError != null) {
      setPasswordsDoNotMatchError(null);
    }
  };

  return (
    <MainLayout isAuthLayout={true}>
      <ResetPasswordContainer size={ModalSize.LARGE} sentEmail={true}>
        <LogTitleContainerStyled>
          <StyledLink to={PAGES.BASE.route}>
            <Logo />
          </StyledLink>
        </LogTitleContainerStyled>

        <LowerContainer>
          <TitleAndInputContainer>
            <ForgotPasswordTitle variant="h5">
              {t("forgotPassword.resetPassword")}
            </ForgotPasswordTitle>
            <InputFieldContainer>
              <PasswordField formik={formik} required height={"50px"} />
              <PasswordField
                label="forgotPassword.repeatPassword"
                name={FormikParams.confirmedPassword}
                placeholder="forgotPassword.repeatPassword"
                formik={formik}
                required
                helperText={passwordsDoNotMatchError}
                clearErrorMessage={clearPasswordConfirmErrorMessage}
                height={"50px"}
              />
            </InputFieldContainer>
          </TitleAndInputContainer>
          <ButtonWrapper>
            <StyledButton onClick={formik.handleSubmit}>
              {t("forgotPassword.savePassword")}
            </StyledButton>
          </ButtonWrapper>
        </LowerContainer>
      </ResetPasswordContainer>
    </MainLayout>
  );
};

export default ResetPassword;
