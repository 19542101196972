import { Box, Typography } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const RightAuthContainer = styled.div`
  max-width: 1200px;
  width: 666px;
  height: 720px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  padding: 56px 48px 10px 48px;
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      justify-content: flex-start;
      flex-direction: column;
      width: calc(100% - 32px);
      gap: 0px;
      padding: 0px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: calc(100% - 72px);
      max-height: 599px;
      max-width: 343px;
      width: calc(100% - 32px);
      @media (orientation: landscape) {
        width: calc(100% - 32px);
        max-width: 600px;
        height: calc(100% - 32px);
        margin-top: 4px;
        margin-bottom: 50px;
        max-height: 350px;
      }
    }
  }
`;

export const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 600px;
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      height: fit-content;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: fit-content;
      @media (orientation: landscape) {
      }
    }
  }
`;

export const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      gap: 16px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 16px;
      @media (orientation: landscape) {
      }
    }
  }
`;

export const UpperContainerLogIn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      gap: 0px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      @media (orientation: landscape) {
      }
    }
  }
`;
export const LogoStyledNavLink = styled(NavLink)`
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      @media (orientation: landscape) {
        height: 45px;
      }
    }
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: 24px;
      width: 90.68px;
      @media (orientation: landscape) {
        height: 45px;
      }
    }
  }
`;

export const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      & .css-1wc848c-MuiFormHelperText-root {
        margin-top: 0px;
        line-height: 1.2;
      }
      gap: 24px;
      width: 100%;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 16px;
      @media (orientation: landscape) {
      }
    }
  }
`;

export const InputFieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: row;
  gap: ${(props) => (props?.gap ? "3px" : "16px")};
  ${(props) =>
    props?.twoInputs
      ? css`
          & > div {
            width: 275px;
          }
        `
      : css`
          & > div {
            width: 566px;
          }
        `}
  > div {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      flex-direction: column;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      > div {
        width: 100%;
      }
      @media (orientation: landscape) {
      }
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 16px;
      flex-direction: column;
    }
  }
`;

export const LowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props?.flexDirection};
  text-align: ${(props) => props?.textAlign};
  font-size: ${(props) => props?.fontSize || "16px"};
  font-weight: ${(props) => props?.fontWeight};
  justify-content: ${(props) => props?.justifyContent};
  color: ${(props) => props?.color};
  align-items: center;
  gap: 5px;
`;

export const RegTitleContainer = styled.div`
  display: flex;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.74px;
`;

export const SentCodeMessageContainer = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const SentCodeBtnContainer = styled.div`
  display: flex;
  justify-content: right;
`;

export const NextBackBtnsContainer = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props?.twoButtons ? "space-between" : "right"};
`;

export const BottomTextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      padding-top: 35px;
      @media (orientation: landscape) {
      }
    }
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding-top: 50px;
      @media (orientation: landscape) {
      }
    }
  }
`;

export const FirstText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  line-height: 21px;
  font-family: "Urbanist" !important;
`;

export const ForgotPWLinkStyled = styled(Link)`
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  padding-right: 35px;
  font-family: "Urbanist" !important;
  color: ${(props) => props?.theme?.colors?.text3};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      padding: 8px 100px 16px 0;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding: 6px 25px 16px 0;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      @media (orientation: landscape) {
      }
    }
  }
`;

export const LogRegLinkStyled = styled(Link)`
  font-size: 15px;
  font-weight: 500;
  color: ${(props) => props.color};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  padding-top: 25px;
  font-family: "Baloo 2" !important;
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      font-size: 28px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding-top: 0px;
      font-size: 24px;
      line-height: 24px;
      @media (orientation: landscape) {
      }
    }
  }
`;

export const LogTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      gap: 8px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 8px;
      @media (orientation: landscape) {
      }
    }
  }
`;

export const OrDividerGoogleBtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      gap: 16px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 8px;
      @media (orientation: landscape) {
      }
    }
  }
`;

export const JustifiedRightItems = styled.div`
  display: flex;
  justify-content: right;
`;
