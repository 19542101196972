import React from "react";
import { Trans } from "react-i18next";
import { FooterContainer, MiniLogo } from "./Footer.styled";
import { useSelector } from "react-redux";
import { selectIsSidebarCollapsed } from "features/sidebar/sidebarSlice";
import PropTypes from "prop-types";

const Footer = (props) => {
  const isCollapsed = useSelector(selectIsSidebarCollapsed);
  return (
    <FooterContainer
      $authLayout={props?.authLayout}
      $isSidebarOpened={!isCollapsed}
    >
      <Trans
        i18nKey="footer.title"
        components={{
          span: <span />,
          0: <MiniLogo />,
          1: (
            <a
              href="https://tremium.net/"
              target="_blank"
              rel="noopener noreferrer"
              className="tremium-link"
            ></a>
          ),
        }}
      />
    </FooterContainer>
  );
};

Footer.propTypes = {
  authLayout: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Footer;
