import Button from "components/Button/Button";
import styled from "styled-components";
import { ReactComponent as ServiceImage } from "assets/images/svg/default-service-image.svg";
import { ReactComponent as ClockIcon } from "assets/images/svg/clock-icon.svg";
import { ReactComponent as WalletIcon } from "assets/images/svg/wallet-icon.svg";
import { TextField, Typography } from "@mui/material";

export const ChosenSlotInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const ServiceImageStyled = styled(ServiceImage)`
  border-radius: 16px;
  width: max-content;
  height: 200px;
  align-self: center;
  width: 100%;
`;

export const ServiceIconStyled = styled.img`
  height: 200px;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

export const LowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 5px 18px 0px 18px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ServiceName = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ResourcesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemContainer = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: ${(props) => props?.theme?.colors?.textColor};
`;

export const DurationAndPriceContainer = styled(ItemContainer)`
  color: ${(props) => props?.theme?.colors?.darkBlue};
  display: flex;
  gap: 7px;
  align-self: flex-end;
`;

export const ClockIconStyled = styled(ClockIcon)`
  height: 25px;
  width: 25px;
  align-self: center;
`;

export const WalletIconStyled = styled(WalletIcon)`
  height: 25px;
  width: 25px;
  align-self: center;
`;

export const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
`;

export const TextFieldStyled = styled(TextField)`
  & .MuiOutlinedInput-root {
    & fieldset {
      border-radius: 12px;
      min-height: 75px;
      border-width: 2px;
      border-top-width: 3px;
    }
    &:hover fieldset {
      border-top-width: 3px;
      border-color: ${(props) => props?.theme?.colors?.secondaryColor};
    }
    &.Mui-focused fieldset {
      border-top-width: 3px;
      border-color: ${({ isClient, theme }) =>
        isClient ? theme?.colors?.text3 : theme?.colors?.secondaryColor};
    }
  }
  & .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: ${(props) => props?.theme?.colors?.textColor};
  }
  & .MuiInputBase-root {
    padding: 8px 8px 8px 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
  width: 100%;
  height: 72px;
  overflow-y: auto;
`;

export const ScheduleButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  width: 89px;
  height: 33px;
  border-radius: 12px;
  background-color: ${(props) => props?.theme?.colors?.updateTableButton};
  color: ${(props) => props?.theme?.colors?.white};
  :hover {
    background-color: ${(props) => props?.theme?.colors?.updateTableButton};
  }
`;
