import React, { useRef } from "react";
import { dateFnsLocalizer } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import PropTypes from "prop-types";
import enUS from "date-fns/locale/en-US";
import srLatn from "date-fns/locale/sr-Latn";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { CalendarComponentStyled } from "./CalendarComponent.styled";
import CustomToolbar from "./CustomToolbar";
import { authScopeStringGetHelper } from "util/authScopeHelpers";
import { LANGUAGE } from "constants/localStorage";
import { useTranslation } from "react-i18next";
import primary from "themes/primaryTheme/primaryTheme";
import { calendarFormats } from "util/dateHelpers";
import { getRandomColor } from "util/colorHelper";
import { useDispatch } from "react-redux";
import { setCalendarInformationAndCancellationModal } from "features/modal/modalSlice";
import { mapCalendarEvents } from "util/mapCalendarEvents";

const MyCalendar = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let lang = authScopeStringGetHelper(LANGUAGE);

  const srLatnCustom = {
    ...srLatn,
    localize: {
      ...srLatn.localize,
      day: (n, options) =>
        srLatn.localize.day(n, options).charAt(0).toUpperCase() +
        srLatn.localize.day(n, options).slice(1),
      month: (n, options) =>
        srLatn.localize.month(n, options).charAt(0).toUpperCase() +
        srLatn.localize.month(n, options).slice(1),
    },
  };

  const locales = {
    "en-US": enUS,
    "sr-Latn": srLatnCustom,
  };

  const startOfWeekWithMonday = (date) =>
    startOfWeek(date, { weekStartsOn: 1, locale: locales[lang] });

  const localizer = dateFnsLocalizer({
    format: (date, formatStr) =>
      format(date, formatStr, { locale: locales[lang] }),
    parse: (str, formatStr) =>
      parse(str, formatStr, new Date(), { locale: locales[lang] }),
    startOfWeek: startOfWeekWithMonday,
    getDay,
    locales,
  });

  const formats = calendarFormats(locales[lang]);
  const serviceColorsRef = useRef(new Map());

  const mappedEvents =
    props?.appointments?.map((appointment) => ({
      title: appointment?.clientService?.name || "No Title",
      start: mapCalendarEvents(appointment.fromTimeUtc),
      end: mapCalendarEvents(appointment.toTimeUtc),
      price: appointment?.clientService?.price,
      appointmentStatus: appointment?.status,
      attributes: appointment?.chosenAttributes,
      appointmentId: appointment?.id,
      clientId: appointment?.client?.id,
      userId: appointment?.user?.id,
      serviceId: appointment?.clientService?.id,
      resources: appointment?.allocatedResources?.map(resource => resource.resource) || [],
      userFirstName: appointment?.user?.firstName,
      userLastName: appointment?.user?.lastName,
      userPhoneNumber: appointment?.user?.phoneNumber,
    })) || [];

  const eventPropGetter = (event) => {
    if (!serviceColorsRef.current.has(event.serviceId)) {
      serviceColorsRef.current.set(event.serviceId, getRandomColor());
    }

    const backgroundColor = serviceColorsRef.current.get(event.serviceId);
    return {
      style: {
        backgroundColor,
        color: primary.colors.black,
        borderRadius: "4px",
        border: "none",
      },
    };
  };

  const messages = {
    date: t("calendar.date"),
    time: t("calendar.time"),
    event: t("calendar.event"),
    noEventsInRange: t("calendar.noEventsInRange"),
  };

  const handleSelectEvent = (event) => {
    dispatch(setCalendarInformationAndCancellationModal({appointment: event, isUser: props?.isUser}));
  };
  
  return (
    <CalendarComponentStyled
      localizer={localizer}
      culture={lang}
      events={mappedEvents}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500 }}
      defaultView="month"
      eventPropGetter={eventPropGetter}
      components={{
        toolbar: CustomToolbar,
      }}
      dayPropGetter={(date) => {
        const today = new Date();
        if (date.toDateString() === today.toDateString()) {
          return { style: { backgroundColor: "transparent" } };
        }
        return {};
      }}
      formats={formats}
      messages={messages}
      onSelectEvent={handleSelectEvent}
      isUser={props?.isUser}
    />
  );
};

MyCalendar.propTypes = {
  appointments: PropTypes.any,
  isUser: PropTypes.bool,
};

MyCalendar.defaultProps = {
  isUser: false,
};

export default MyCalendar;
