import { primaryThemeFonts } from "themes/primaryTheme/primaryThemeFonts";
import { secondaryThemeColors } from "./secondaryThemeColors";
import { primaryBreakpoints } from "themes/primaryTheme/primaryBreakpoints";

const secondary = {
  colors: secondaryThemeColors,
  fonts: primaryThemeFonts,
  breakpoints: primaryBreakpoints,
};

export default secondary;
