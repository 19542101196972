import React from "react";
import { toast } from "react-toastify";
import { ReactComponent as CloseImage } from "assets/images/svg/x-icon.svg";
import styled from "styled-components";
import ErrorIcon from "assets/images/svg/error-icon.svg";
import WarningIcon from "assets/images/svg/warning-icon.svg";

const defaultOptions = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  pauseOnFocusLoss: false,
  draggable: true,
  style: {
    borderRadius: "16px",
  },
  closeButton: ({ closeToast }) => <CloseImageStyled onClick={closeToast} />,
};

const toastClasses = {
  success: "toast-success",
  error: "toast-error",
  warning: "toast-warning",
  notification: "toast-notification",
};

const StyledIcon = styled.img`
  padding-right: 5px;
  height: 32px;
  width: 32px;
`;

const toastIcons = {
  error: <StyledIcon src={ErrorIcon} alt="error" />,
  warning: <StyledIcon src={WarningIcon} alt="warning" />,
};

export const makeToastMessage = (message, type = "info", options = {}) => {
  const toastOptions = {
    ...defaultOptions,
    ...options,
    className: toastClasses[type],
    icon: toastIcons[type],
  };
  switch (type) {
    case "success":
      return toast.success(message, toastOptions);
    case "error":
      return toast.error(message, toastOptions);
    case "warning":
      return toast.warning(message, toastOptions);
    case "notification":
      return toast.info(message, toastOptions);
    default:
      return toast.info(message, toastOptions);
  }
};

const CloseImageStyled = styled(CloseImage)`
  margin-top: 5px;
  margin-right: 5px;
`;
