import React from "react";
import PropTypes from "prop-types";
import {
  ProgressContainer,
  Title,
  Step,
  Circle,
  Line,
  Label,
  CheckIcon,
  LogImg,
  ContentContainer,
  Container,
} from "./LeftAuthContent.styled";
import AuthType from "constants/authType";
import { LogoReg } from "components/Header/Header.styled";
import { StyledLink } from "components/Sidebar/Sidebar.styled";
import { PAGES } from "constants/pages";

const LeftAuthContent = (props) => {
  if (props.type === AuthType.LOGIN) {
    return (
      <Container>
        <LogImg />
      </Container>
    );
  }

  return (
    <ProgressContainer>
      <StyledLink to={PAGES.BASE.route}>
        <LogoReg />
      </StyledLink>
      <Title>{props.title}</Title>
      {props.steps.map((step, index) => (
        <Step key={index}>
          <ContentContainer>
            <Circle type={props.type} completed={step.completed}>
              <span>{step.completed ? <CheckIcon /> : index + 1}</span>
            </Circle>
            <Label completed={step.completed}> {step.label} </Label>
          </ContentContainer>
          {index < props.steps.length - 1 && (
            <Line type={props.type} completed={step.completed} />
          )}
        </Step>
      ))}
    </ProgressContainer>
  );
};

LeftAuthContent.propTypes = {
  theme: PropTypes.any,
  type: PropTypes.string.isRequired,
  steps: PropTypes.array,
  title: PropTypes.string,
};

export default LeftAuthContent;
