import styled from "styled-components";

const OrDividerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  gap: 15px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 100%;
    }
  }
`;

export default OrDividerStyled;
