import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";

export const CalendarContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const CalendarContentContainerFilerAndSelect = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SelectStyled = styled.select`
  width: 200px;
  height: 30px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => hexToRGB(theme?.colors?.black, 0.3)};
  padding: 5px;
  text-align: center;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
`;
