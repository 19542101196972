import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  UpperContainer,
  Title,
  TitleContainer,
  CloseIcon,
  InputFieldsContainer,
  LowerContainer,
  ActionsContainer,
  CancelActionButton,
  ActionButton,
} from "./AddAppointmentModal.styled";
import { useDispatch } from "react-redux";
import { unsetShownModal } from "features/modal/modalSlice";
import { useTranslation } from "react-i18next";
import { InputFieldContainer } from "components/RightAuthContent/RightAuthContent.styled";
import DateField from "components/SuperAdmin/LicenseAddEditModal/InputFields/DateField";
import { VARIANT } from "constants/buttonConstants";
import CustomMultipleSelectField from "components/InputFields/CustomFields/CustomMultipleSelectField";
import { NoteContainer } from "components/SchedulingAppointmentContent/ChosenSlotInfo/ChosenSlotInfo.styled";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import { useFormik } from "formik";
import { FormikAppointmentParams } from "initialValues/appointmentInitialValues";
import { LabelContainer } from "components/Modals/ClientServiceModals/Modal.styled";
import CustomInputField from "components/InputFields/CustomFields/CustomInputField";
import appointmentValidation from "validations/appointmentValidation";
import { useScheduleAppointmentAsClientAdminMutation } from "features/appointmentSlice/appointmentApiSlice";
import { makeToastMessage } from "util/toastMessage";
import toastNotificationType from "constants/toastNotificationType";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";

const AddAppointmentModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [scheduleAppointmentAsClientAdmin] =
    useScheduleAppointmentAsClientAdminMutation();

  const handleSubmit = async () => {
    const data = {
      fromTimeUtc: new Date(formik?.values?.startDateUtc).toISOString(),
      toTimeUtc: new Date(formik?.values?.endDateUtc).toISOString(),
      note: formik?.values?.note,
      clientResourceIds: formik?.values?.resourceIds || [],
    };

    const response = await scheduleAppointmentAsClientAdmin({
      clientId: props?.client?.id,
      data,
    });

    if (response?.error) {
      makeToastMessage(response.error.data.errors[0].message, "error");
    } else {
      makeToastMessage(
        t("scheduleAppointment.successfullyScheduledAppointment"),
        toastNotificationType.SUCCESS
      );
      dispatch(unsetShownModal());

      navigate(PAGES.APPOINTMENTS.route);
    }
  };

  const formik = useFormik({
    initialValues: FormikAppointmentParams,
    validationSchema: appointmentValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
  });

  return (
    <Modal>
      <UpperContainer>
        <TitleContainer>
          <Title>{t("common.resourceReservation")}</Title>
          <CloseIcon onClick={() => dispatch(unsetShownModal())} />
        </TitleContainer>
        <InputFieldsContainer>
          <InputFieldContainer>
            <DateField
              formik={formik}
              dateTime
              label="appointments.fromDate"
              name="startDateUtc"
              helperText={formik.errors.startDateUtc}
            />
          </InputFieldContainer>
          <InputFieldContainer>
            <DateField
              formik={formik}
              dateTime
              label="appointments.toDate"
              name="endDateUtc"
              helperText={formik.errors.endDateUtc}
            />
          </InputFieldContainer>
          <InputFieldContainer gap>
            <LabelContainer>
              <CustomFieldLabel label={t("clientService.resourceList")} />
            </LabelContainer>
            <CustomMultipleSelectField
              name="resourceIds"
              formik={formik}
              fieldIds="resourceIds"
              fieldNames="resourceNames"
              isClient={false}
              items={props?.client?.resources.map((x) => ({
                name: x?.name,
                id: x?.id,
                primaryId: x?.id,
                isActive: x?.isActive,
              }))}
            />
          </InputFieldContainer>
          <InputFieldContainer>
            <NoteContainer>
              <CustomFieldLabel label={t("scheduleAppointment.noteLabel")} />
              <CustomInputField
                formik={formik}
                multiline
                name="note"
                placeholder={t("scheduleAppointment.notePlaceholder")}
              />
            </NoteContainer>
          </InputFieldContainer>
        </InputFieldsContainer>
      </UpperContainer>
      <LowerContainer>
        <ActionsContainer>
          <CancelActionButton
            variant={VARIANT.OUTLINED}
            onClick={() => dispatch(unsetShownModal())}
            value={t("common.quit")}
          />
          <ActionButton
            onClick={formik.handleSubmit}
            value={t("common.save")}
          />
        </ActionsContainer>
      </LowerContainer>
    </Modal>
  );
};

AddAppointmentModal.propTypes = {
  client: PropTypes.any,
};

export default AddAppointmentModal;
