import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ClientLogoStyled,
  ClientLogoWrapper,
  LogoContainer,
} from "components/ClientEditTab/ClientContentTab/TabContent/Information/LeftInfoContent/ClientLogo.styled";
import {
  BasicInfo,
  BasicInfoContainer,
  ClientDescription,
  ClientInfoContainer,
  ClientName,
  ClientFullName,
  SegmentWithBottomBorderWrapper,
  ClientPrimaryService,
  SegmentWrapper,
  ClientWebAddress,
  SegmentTitle,
  LowerContainer,
  DividerLine,
} from "./ClientInfoContent.styled";
import { useTranslation } from "react-i18next";
import ListingWorkingHourComponent from "./ListingWorkingHourComponent/ListingWorkingHourComponent";
import { useGetClientLogoQuery } from "features/images/imagesApiSlice";
import defaultLogo from "assets/images/svg/default-ClientLogo.png";
import SubscribeButton from "components/Button/SubscribeButton/SubscribeButton";
import { selectCurrentUser } from "features/auth/authSlice";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
import { applyFontStyles } from "util/helpers/fontHelper";

const ClientInfoContent = (props) => {
  const { t } = useTranslation();
  const user = useSelector(selectCurrentUser);
  const { data, isError } = useGetClientLogoQuery({
    id: props?.client?.logoId,
  });
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (data && data.imageUrl && !isError) {
      setImageUrl(data.imageUrl);
    } else {
      setImageUrl(defaultLogo);
    }
    return () => {
      if (imageUrl && imageUrl !== defaultLogo) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [data]);

  const isSubscribed = props?.client?.subscribedUsers?.some(
    (subscription) =>
      subscription?.user?.id == user?.id && subscription?.endDateUtc == null
  );

  const sanitizedDescription = DOMPurify.sanitize(props?.client?.description, {
    ADD_ATTR: ['style'],
  });
  const formattedDescription = applyFontStyles(sanitizedDescription);

  return (
    <ClientInfoContainer>
      <ClientLogoWrapper>
        <LogoContainer>
          <ClientLogoStyled src={imageUrl} alt="Client Logo" />
        </LogoContainer>
        <ClientName>{props?.client?.fullName}</ClientName>
        <BasicInfoContainer>
          <BasicInfo>{`${props?.client?.address}, ${props?.client?.city}, ${props?.client?.country}`}</BasicInfo>
          <BasicInfo>{props?.client?.phoneNumbers[0]?.phoneNumber}</BasicInfo>
        </BasicInfoContainer>
        {user && user.clientId !== props?.client?.id && (
          <SubscribeButton
            isSubscribed={isSubscribed}
            isClientPanel
            userId={user?.id}
            clientId={props?.client?.id}
          />
        )}
      </ClientLogoWrapper>
      <LowerContainer>
        <DividerLine />
        <SegmentWrapper>
          <ClientDescription
            dangerouslySetInnerHTML={{ __html: formattedDescription }}
          />
        </SegmentWrapper>
        <SegmentWrapper>
          <SegmentTitle>
            {t("clientPanel.infoSegment.title.workingHours")}
          </SegmentTitle>
          <ListingWorkingHourComponent
            items={props?.client?.workingDaysHours}
            itemsPerRow={2}
          />
        </SegmentWrapper>
        <SegmentWithBottomBorderWrapper>
          <SegmentTitle>
            {t("clientPanel.infoSegment.title.fullName")}
          </SegmentTitle>
          <ClientFullName>{props?.client?.fullName}</ClientFullName>
        </SegmentWithBottomBorderWrapper>
        <SegmentWithBottomBorderWrapper>
          <SegmentTitle>
            {t("clientPanel.infoSegment.title.primaryService")}
          </SegmentTitle>
          <ClientPrimaryService>
            {props?.client?.primaryServiceCategory?.name}
          </ClientPrimaryService>
        </SegmentWithBottomBorderWrapper>
        <SegmentWrapper>
          <SegmentTitle>
            {t("clientPanel.infoSegment.title.webAddress")}
          </SegmentTitle>
          <ClientWebAddress>{props?.client?.webAddress}</ClientWebAddress>
        </SegmentWrapper>
      </LowerContainer>
    </ClientInfoContainer>
  );
};

ClientInfoContent.propTypes = {
  client: PropTypes.any,
};

export default ClientInfoContent;
