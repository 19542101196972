import Button from "components/Button/Button";
import styled from "styled-components";

export const LogInButtonContainer = styled(Button)`
  font-size: 24px;
  font-weight: 600;
  line-height: 32.74px;
  background-color: ${(props) =>
    props?.theme?.colors?.leftAuthContentBackground};
  color: ${(props) => props?.theme?.colors?.iconColor};
  width: 100%;
  &:hover {
    background-color: ${(props) =>
      props?.theme?.colors?.leftAuthContentBackground};
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      @media (orientation: landscape) {
        padding: 0;
      }
      align-self: center;
      width: calc(100% - 150px);
      font-size: 22px;
      font-weight: 600;
      line-height: 30.74px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 100%;
      font-size: 20px;
      font-weight: 600;
      line-height: 27.28px;
      @media (orientation: landscape) {
        font-size: 14px;
      }
    }
  }
`;
