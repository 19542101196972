import { primaryBreakpoints } from "./primaryBreakpoints";
import { primaryThemeColors } from "./primaryThemeColors";
import { primaryThemeFonts } from "./primaryThemeFonts";

const primary = {
  colors: primaryThemeColors,
  fonts: primaryThemeFonts,
  breakpoints: primaryBreakpoints,
};

export default primary;
