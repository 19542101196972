import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Table as TableComponent } from "@mui/material";
import TableFooterControls from "components/Table/TableFooterPagination";
import ListingItemsComponent from "components/ClientsContent/ListingClientsComponent/ListingClientsComponent";
import { useGetClientsForServiceCategoryQuery } from "features/serviceCategory/serviceCategoryApiSlice";
import { ServicesTableContainerStyled } from "components/ClientPanelForUsers/ServicesContent/ServicesTable/ServicesTable.styled";

const ClientsTable = (props) => {
  const startingNumberOfItemsPerPage = 15;
  const incrementNumberOfItemsPerPage = 5;
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(startingNumberOfItemsPerPage);
  const [totalRows, setTotalRows] = useState(0);

  const { data: paginatedData } = useGetClientsForServiceCategoryQuery({
    pageNumber: page,
    pageSize: rowsPerPage,
    filters: props.appliedFilters,
  });

  useEffect(() => {
    if (paginatedData) {
      setRows(paginatedData.data?.clients);
      setTotalRows(paginatedData.totalCount);
    }
  }, [paginatedData, page, rowsPerPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const totalPages = Math.ceil(totalRows / rowsPerPage);

  return (
    <ServicesTableContainerStyled isClient>
      <TableComponent>
        <ListingItemsComponent items={rows} />
        <TableFooterControls
          page={page}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          totalRows={totalRows}
          handleRowsPerPageChange={handleRowsPerPageChange}
          handlePageChange={handlePageChange}
          t={t}
          startingNumberOfItemsPerPage={startingNumberOfItemsPerPage}
          incrementNumberOfItemsPerPage={incrementNumberOfItemsPerPage}
        />
      </TableComponent>
    </ServicesTableContainerStyled>
  );
};

ClientsTable.propTypes = {
  appliedFilters: PropTypes.object,
  serviceCategoryId: PropTypes.string,
};

export default ClientsTable;
