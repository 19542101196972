import { Box, Button, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as LeftArrow } from "assets/images/svg/left-arrow-icon.svg";
import { ReactComponent as RightArrow } from "assets/images/svg/right-arrow-icon.svg";

export const LeftArrowStyled = styled(LeftArrow)`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;`;

export const RightArrowStyled = styled(RightArrow)`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;`;

export const ToolbarContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 33px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
`;

export const NavButton = styled(Button)`
  background: transparent;
  border: none;
  min-width: auto;
  padding: 0 8px;
  color: ${(props) => props?.theme?.colors?.black};

  &:hover {
    color: ${(props) => props?.theme?.colors?.hoverDarkBlue};
    background: none;
  }
`;

export const DateLabel = styled(Typography)`
  font-weight: bold;
  font-size: 20px;
  font-weight: 400px;
  line-height: 30px;
  color: ${(props) => props?.theme?.colors?.black};
`;

export const ViewButton = styled(Button)`
  background-color: ${(props) => props?.theme?.colors?.white};
  border: 1px solid ${(props) => props?.theme?.colors?.timeSlotBg};
  border-radius: 8px;
  height: 24px;
  min-width: 70px;
  font-size: 0.9rem;
  text-transform: none;
  color: ${(props) => props?.theme?.colors?.black};

  &.active {
    background-color: ${(props) => props?.theme?.colors?.timeSlotBg}
  }

  &:hover {
    background-color: ${(props) => props?.theme?.colors?.hoverDarkBlue};
    color: ${(props) => props?.theme?.colors?.white};
  }
`;

export const ViewButtonGroup = styled(Box)`
  display: flex;
  gap: 8px;
`;