import { apiSlice } from "features/api/apiSlice";
import apiEndpoints from "features/apiEndpoints";

export const appointmentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAvailableTimeSlots: builder.query({
      query: (data) => ({
        url: apiEndpoints.appointments.getAvailableTimeSlots(data),
      }),
    }),
    scheduleAppointment: builder.mutation({
      query: ({ userId, data }) => ({
        url: apiEndpoints.appointments.addAppointment(userId),
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Appointments"],
    }),
    getPaginatedAppointmentsForClient: builder.query({
      query: ({ id, pageNumber = 1, pageSize = 10, filters = {} }) => ({
        url: apiEndpoints.appointments.getPaginatedForClient(
          id,
          pageNumber,
          pageSize,
          filters
        ),
      }),
      providesTags: ["Appointments"],
    }),
    scheduleAppointmentAsClientAdmin: builder.mutation({
      query: ({ clientId, data }) => ({
        url: apiEndpoints.appointments.addAppointmentAsClientAdmin(clientId),
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Appointments"],
    }),
    getPaginatedAppointmentsForUser: builder.query({
      query: ({ id, pageNumber = 1, pageSize = 10, filters = {} }) => ({
        url: apiEndpoints.appointments.getPaginatedForUser(
          id,
          pageNumber,
          pageSize,
          filters
        ),
      }),
      providesTags: ["Appointments"],
    }),
    cancelAppointment: builder.mutation({
      query: ({ appointmentId, userId }) => ({
        url: apiEndpoints.appointments.cancelAppointment(appointmentId, userId),
        method: "PUT",
      }),
      invalidatesTags: ["Appointments"],
    }),
    cancelAppointmentByClient: builder.mutation({
      query: ({ appointmentId, clientId }) => ({
        url: apiEndpoints.appointments.cancelAppointmentByClient(appointmentId, clientId),
        method: "PUT",
      }),
      invalidatesTags: ["Appointments"],
    }),
    makeWishForAppointment: builder.mutation({
      query: ({ userId, data }) => ({
        url: apiEndpoints.appointments.makeWishForAppointment(userId),
        method: "POST",
        body: data,
      }),
    }),
    getUserWishList: builder.query({
      query: ({ userId }) => ({
        url: apiEndpoints.users.getUserWishList(userId),
        method: "GET",
      }),
      invalidatesTags: ["Appointments"],
    }),
    getAppointmentsForClientEmployee: builder.query({
      query: ({ userId, pageNumber = 1, pageSize = 1, filters = {} }) => ({
        url: apiEndpoints.appointments.getClientEmployeeAppointments(
          userId,
          pageNumber,
          pageSize,
          filters
        ),
        method: "GET",
      }),
      invalidatesTags: ["Appointments"],
    }),
  }),
});

export const {
  useMakeWishForAppointmentMutation,
  useGetAppointmentsForClientEmployeeQuery,
  useGetUserWishListQuery,
  useGetAvailableTimeSlotsQuery,
  useScheduleAppointmentMutation,
  useGetPaginatedAppointmentsForClientQuery,
  useScheduleAppointmentAsClientAdminMutation,
  useGetPaginatedAppointmentsForUserQuery,
  useCancelAppointmentMutation,
  useCancelAppointmentByClientMutation,
} = appointmentApi;
