import React from "react";
import PropTypes from "prop-types";
import {
  ItemContainer,
  ChosenSlotInfoContainer,
  ContentContainer,
  InfoContainer,
  LowerContainer,
  ScheduleButton,
  ServiceImageStyled,
  ServiceName,
  DurationAndPriceContainer,
  ClockIconStyled,
  WalletIconStyled,
  NoteContainer,
  TextFieldStyled,
  ServiceIconStyled,
} from "./ChosenSlotInfo.styled";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { convertUTCToLocalTime } from "util/helpers/dateHelpers";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import { useGetImageQuery } from "features/clients/clientsApiSlice";

const ChosenSlotInfo = (props) => {
  const { t } = useTranslation();

  const duration =
    props.clientService.durationInMinutes +
    props?.selectedAttributes?.reduce?.(
      (acc, atr) => acc + atr.durationInMinutes,
      0
    );
  const price =
    props.clientService.price +
    props?.selectedAttributes?.reduce?.((acc, atr) => acc + atr.price, 0);

  const { data: image, isLoading } = useGetImageQuery(
    props?.clientService?.imageId
  );

  return (
    <ChosenSlotInfoContainer>
      {isLoading || !image ? (
        <ServiceImageStyled />
      ) : (
        <ServiceIconStyled src={image} />
      )}
      <LowerContainer>
        <InfoContainer>
          <ServiceName>{props.chosenSlot.serviceName}</ServiceName>
          <ContentContainer>
            {props?.selectedAttributes?.map((attribute) => (
              <ItemContainer
                key={attribute.id}
              >{`${attribute.name}`}</ItemContainer>
            ))}
            {Object.entries(props?.chosenSlot?.resources)?.map(
              ([key, value]) => (
                <ItemContainer
                  key={key}
                >{`${key}: ${value.resource.name}`}</ItemContainer>
              )
            )}
            <ItemContainer>
              {dayjs(props.chosenSlot.date).format("DD.MM.YYYY")}
            </ItemContainer>
            <ItemContainer>
              {convertUTCToLocalTime(props.chosenSlot.startTime) +
                "-" +
                convertUTCToLocalTime(props.chosenSlot.endTime) +
                "h"}
            </ItemContainer>
            <DurationAndPriceContainer>
              <ClockIconStyled />
              {duration + "min"}
            </DurationAndPriceContainer>
            <DurationAndPriceContainer>
              <WalletIconStyled />
              {price + "din"}
            </DurationAndPriceContainer>
          </ContentContainer>
          <NoteContainer>
            <CustomFieldLabel label={t("scheduleAppointment.noteLabel")} />
            <TextFieldStyled
              multiline
              name="description"
              placeholder={t("scheduleAppointment.notePlaceholder")}
              onChange={(event) => props.setNote(event.target.value)}
            />
          </NoteContainer>
        </InfoContainer>

        <ScheduleButton
          onClick={props.onSchedule}
          value={t("common.schedule")}
        ></ScheduleButton>
      </LowerContainer>
    </ChosenSlotInfoContainer>
  );
};

ChosenSlotInfo.propTypes = {
  clientService: PropTypes.object,
  chosenSlot: PropTypes.object,
  selectedAttributes: PropTypes.object,
  onSchedule: PropTypes.func,
  setNote: PropTypes.func,
};

export default ChosenSlotInfo;
