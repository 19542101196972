import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeToastMessage } from "util/toastMessage";
import { unsetShownModal } from "features/modal/modalSlice";
import toastNotificationType from "constants/toastNotificationType";
import { useFormik } from "formik";
import {
  ActionButton,
  ActionsContainer,
  CancelActionButton,
  CloseIcon,
  InputFieldsContainer,
  LowerContainer,
  Modal,
  Title,
  TitleContainer,
  UpperContainer,
} from "./MakeWishForAppointmentModal.styled";
import { InputFieldContainer } from "components/RightAuthContent/RightAuthContent.styled";
import DateField from "components/SuperAdmin/LicenseAddEditModal/InputFields/DateField";
import { LabelContainer } from "components/Modals/ClientServiceModals/Modal.styled";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import CustomMultipleSelectField from "components/InputFields/CustomFields/CustomMultipleSelectField";
import { VARIANT } from "constants/buttonConstants";
import { makeWishForAppointmentValues } from "initialValues/makeWishForAppointmentValues";
import ResourcesContent from "../ServiceParamesContent/ResourcesContent/ResourcesContent";
import makeWishForAppointmentValidation from "validations/makeWishForAppointmentValidation";
import { useMakeWishForAppointmentMutation } from "features/appointmentSlice/appointmentApiSlice";
import { selectCurrentUser } from "features/auth/authSlice";

const MakeWishForAppointmentModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [makeWishForAppointment] = useMakeWishForAppointmentMutation();
  const [selectedResources, setSelectedResources] = useState({});
  const clientService = props?.clientService;
  const user = useSelector(selectCurrentUser);

  const onSelectResource = (resource, key) => {
    setSelectedResources((oldDictionary) => {
      return { ...oldDictionary, [`${key}`]: resource };
    });
  };

  const handleSubmit = async () => {
    const data = {
      fromTimeUtc: new Date(formik?.values?.startDateUtc).toISOString(),
      toTimeUtc: new Date(formik?.values?.endDateUtc).toISOString(),
      clientResourceIds: Object.fromEntries(
        Object.entries(selectedResources).map(([key, resource]) => [
          key,
          resource.id,
        ])
      ),
      clientServiceAttributeIds: formik?.values?.attributeIds || [],
      clientServiceId: clientService?.id,
    };

    const response = await makeWishForAppointment({
      userId: user?.id,
      data,
    });

    if (response?.error) {
      makeToastMessage(response.error.data.errors[0].message, "error");
    } else {
      makeToastMessage(
        t("scheduleAppointment.successfullyScheduledAppointment"),
        toastNotificationType.SUCCESS
      );
      dispatch(unsetShownModal());
    }
  };

  const formik = useFormik({
    initialValues: makeWishForAppointmentValues,
    validationSchema: makeWishForAppointmentValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
  });

  return (
    <Modal>
      <UpperContainer>
        <TitleContainer>
          <Title>{t("scheduleAppointment.makeWishForAppointmentTitle")}</Title>
          <CloseIcon onClick={() => dispatch(unsetShownModal())} />
        </TitleContainer>
        <InputFieldsContainer>
          <InputFieldContainer>
            <DateField
              formik={formik}
              dateTime
              label="scheduleAppointment.makeWishForAppointmentStartTime"
              name="startDateUtc"
              helperText={formik.errors.startDateUtc}
            />
          </InputFieldContainer>
          <InputFieldContainer>
            <DateField
              formik={formik}
              dateTime
              label="scheduleAppointment.makeWishForAppointmentEndTime"
              name="endDateUtc"
              helperText={formik.errors.endDateUtc}
            />
          </InputFieldContainer>
          {!clientService?.requiredResourceTypes?.every(
            (requiredResourceType) => requiredResourceType.autoAssign
          ) && (
            <InputFieldsContainer>
              <ResourcesContent
                requiredResourceTypes={clientService?.requiredResourceTypes}
                onSelectResource={onSelectResource}
              />
            </InputFieldsContainer>
          )}
          <InputFieldContainer gap>
            <LabelContainer>
              <CustomFieldLabel label={t("clientService.attributeList")} />
            </LabelContainer>
            <CustomMultipleSelectField
              name="attributeIds"
              formik={formik}
              fieldIds="attributeIds"
              fieldNames="attributeNames"
              isClient={false}
              items={clientService?.attributes?.map((x) => ({
                name: x?.name,
                id: x?.id,
                primaryId: x?.id,
                isActive: true,
              }))}
            />
          </InputFieldContainer>
        </InputFieldsContainer>
      </UpperContainer>
      <LowerContainer>
        <ActionsContainer>
          <CancelActionButton
            variant={VARIANT.OUTLINED}
            onClick={() => dispatch(unsetShownModal())}
            value={t("common.cancel")}
          />
          <ActionButton
            onClick={formik.handleSubmit}
            value={t("common.save")}
          />
        </ActionsContainer>
      </LowerContainer>
    </Modal>
  );
};

MakeWishForAppointmentModal.propTypes = {
  client: PropTypes.any,
  clientService: PropTypes.any,
  fromTime: PropTypes.string,
  toTime: PropTypes.string,
};

export default MakeWishForAppointmentModal;
