import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  CloseButton,
  CloseImgStyled,
  FullImageModalStyled,
  ModalImage,
} from "./FullImageModal.styled";
import { useDispatch } from "react-redux";
import { unsetShownModal } from "features/modal/modalSlice";

const FullImageModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      dispatch(unsetShownModal());
    }
  };

  return (
    <FullImageModalStyled onClick={handleBackgroundClick}>
      <ModalImage src={props.imageUrl} alt={t("image.error")} />
      <CloseButton onClick={() => dispatch(unsetShownModal())}>
        <CloseImgStyled />
      </CloseButton>
    </FullImageModalStyled>
  );
};

FullImageModal.propTypes = {
  imageUrl: PropTypes.string,
};

export default FullImageModal;
