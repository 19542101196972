import filterValueToRemove from "constants/filters/filterValueToRemove";

export const removeFilter = (valueToRemove, setFilters) => {
  setFilters((oldFilters) => {
    const newFilters = Object.fromEntries(
      Object.entries(oldFilters).filter(
        ([, item]) => item.valueToRemove !== valueToRemove
      )
    );
    return newFilters;
  });
};

export const getValueToRemove = (id) => {
  switch (id) {
    case 2:
      return filterValueToRemove.STATUS_DONE;
    case 0:
      return filterValueToRemove.STATUS_ACTIVE;
    case 1:
      return filterValueToRemove.STATUS_CANCELED;
    default:
      return null;
  }
};