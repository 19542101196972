export const applyFontStyles = (html) => {
  html = html
    .replace(/<span class="ql-font-monospace">/g, '<span style="font-family: monospace;">')
    .replace(/<span class="ql-font-serif">/g, '<span style="font-family: serif;">')
    .replace(/<span class="ql-font-sans-serif">/g, '<span style="font-family: sans-serif;">');
  
  html = html.replace(/<([a-z][^/>]*)>/g, (match, tag) => {
    if (!/style="[^"]*font-family/i.test(match)) {
      return `<${tag} style="font-family: sans-serif;">`;
    }
    return match;
  });

  return html;
};

