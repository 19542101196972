import { SaveButtonWrapper } from "components/ClientEditTab/ClienEditTab.styled";
import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";

export const ClientServiceContainer = styled.div`
  padding: 16px;
  border-radius: 24px;
  display: flex;
  min-width: fit-content;
  background-color: ${(props) => props?.theme?.colors?.backgroundColor};
  box-shadow: 2px 2px 8px ${(props) => props?.theme?.colors?.shadowColor};
`;

export const LeftSide = styled.div`
  border-radius: 24px;
`;

export const RightSideContainer = styled.div`
  margin-right: 30px;
  padding-left: 45px;
  border-radius: 24px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const SaveButton = styled(SaveButtonWrapper)``;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-self: flex-end;
`;

export const InputFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px !important;

  > div {
    width: 100%;
    max-width: none;
    padding: 0px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  padding: 16px;
  gap: 25px;
  box-shadow: 2px 2px 8px ${(props) => hexToRGB(props?.theme?.colors?.boxShadow, 0.3)};
  width: 327px;
  height: auto;
  background-color: ${(props) => props?.theme?.colors?.white};
  max-height: 100%;
  overflow-y: auto;
`;
