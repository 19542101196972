import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ArrowButton,
  GalleryContentContainer,
  ImagesContainer,
  ImagesContainerWrapper,
  LeftArrowContainer,
  LeftArrowStyled,
  RightArrowContainer,
  RightArrowStyled,
} from "./GalleryContent.styled";
import { useGetClientGalleryQuery } from "features/clients/clientsApiSlice";
import { ImageContainer } from "components/ImageGallery/ImageGallery.styled";
import ImageComponent from "components/ImageGallery/Image";

const GalleryContent = (props) => {
  const { data, isLoading } = useGetClientGalleryQuery(props?.client?.id);
  const galleryImages = isLoading ? [] : data?.data?.imageResponses;

  const [visibleImages, setVisibleImages] = useState([]);

  useEffect(() => {
    setVisibleImages([...galleryImages]);
  }, [galleryImages]);

  const handleScrollLeft = () => {
    if (visibleImages.length > 0) {
      setVisibleImages((prevImages) => {
        const lastImage = prevImages[prevImages.length - 1];
        return [lastImage, ...prevImages.slice(0, prevImages.length - 1)];
      });
    }
  };

  const handleScrollRight = () => {
    if (visibleImages.length > 0) {
      setVisibleImages((prevImages) => {
        const firstImage = prevImages[0];
        return [...prevImages.slice(1), firstImage];
      });
    }
  };

  return (
    <>
      <GalleryContentContainer>
        {galleryImages?.length >= 3 && (
          <LeftArrowContainer>
            <ArrowButton>
              <LeftArrowStyled onClick={handleScrollLeft} />
            </ArrowButton>
          </LeftArrowContainer>
        )}
        <ImagesContainerWrapper>
          <ImagesContainer>
            {visibleImages?.map((image, index) => (
              <ImageContainer key={index}>
                <ImageComponent imageId={image?.id} />
              </ImageContainer>
            ))}
          </ImagesContainer>
        </ImagesContainerWrapper>
        {galleryImages?.length >= 3 && (
          <RightArrowContainer>
            <ArrowButton>
              <RightArrowStyled onClick={handleScrollRight} />
            </ArrowButton>
          </RightArrowContainer>
        )}
      </GalleryContentContainer>
    </>
  );
};

GalleryContent.propTypes = {
  client: PropTypes.any,
};

export default GalleryContent;
