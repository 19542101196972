export const mapCalendarEvents = (timeUtc) => {
  const timeParts = timeUtc.split("T");
  const milliseconds = timeParts[1].split(".")[1] || "0";

  const formattedMilliseconds =
    milliseconds.length < 3
      ? milliseconds.padEnd(3, "0")
      : milliseconds.slice(0, 3);

  const timeWithZ =
    timeParts[0] +
    "T" +
    timeParts[1].split(".")[0] +
    "." +
    formattedMilliseconds +
    "Z";

  const fromDate = new Date(timeWithZ);

  return fromDate;
};
